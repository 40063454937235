.card-dropdown {
    .card{
        font-family: var(--primary-font);
        position: relative;
        z-index: 1;
        &.container-card-custom{
            height: 189px;
            padding: 18px 0 0 22px;
        }
    }
    .title{
        color: var(--font-fourth-color);
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-bold);
    }
    .sub-title {
        color: var(--font-primary-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-semi-bold);
        margin-bottom: 30px;
    }
    .dropdown-list{
        padding: 0;
        margin: 0;
        border: 0;
        select {
            font-size: var(--fontSize14);
            font-weight: var(--font-weight-semi-bold);
            option {
                font-size: var(--fontSize14);
                font-weight: var(--font-weight-semi-bold);
            }
        }
    }
}
