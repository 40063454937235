.trigger-toggle-slide-preview-component{
    position: relative;
    .preview-item {
        width: 230px;
        position: relative;
        .avatar {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            background-color: #eee;
            position: absolute;
            top: -6px;
            left: -4px;
            background: no-repeat center center / cover;
        }
        .message-box {
            background-color: var(--primary-color);
            border-radius: 30px;
            height: 60px;
            padding: 5px;
            padding-left: 76px;
            padding-right: 17px;
            font-size: var(--fontSize12);
            color: #fff;
            line-height: 1.45;
            width: 340px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow-wrap: break-word;
            box-shadow: 0 0 5px #00000070;
        }
        .error-message{
            margin: 15px 0 0 0;
            font-size: var(--fontSize12);
            color: var(--danger-primary-color);
            width: 430px;
        }
    }
    .icon-edit {
        width: 27px;
        height: 27px;
        background: url('./../../../public/assets/icons/icon-editing.png');
        top: 49px;
        left: 35px;
        position: absolute;
        border: 0px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        &::before{
            content: '';
            display: inline-block;
            border: 0px;
            border-radius: 3px;
            padding: 15px 8px;
            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
            cursor: pointer;
        }
    }
}
