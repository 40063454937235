.setting-app {
    button {
        background-color: var(--primary-color);
        color: var(--font-third-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-regular);
        border-radius: 15px;
        width: 109px;
        height: 38px;
        border: 0px;
        padding: 0 0 0 16px;
        position: relative;
    }
    form {
        min-height: calc(100vh - 164px);
        position: relative;
        padding-bottom: 65px;
    }
    .container-submit {
        width: 100%;
        height: 60px;
        background-color: var(--background-secondary-color);
        color: var(--font-third-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-regular);
        display: flex;
        padding: 12px 0 0 0;
        margin: 0 0 20px 0;
        border-radius: 15px;
        position: absolute;
        bottom: 0;
        .container-left {
            width: calc(100% - 500px);
        }
        .container-right {
            width: 500px;
            text-align: center;
            button[type="submit"] {
                background-color: var(--primary-color);
                color: var(--third-color);
                &:hover {
                    background-color: #4b3ee1;
                    color: var(--font-third-color);
                    font-size: var(--fontSize16);
                }
            }
            button[type="reset"] {
                background-color: var(--fourth-color);
                color: var(--font-third-color);
                &:hover {
                    background-color: #0e0e0e;
                    color: var(--font-third-color);
                    font-size: var(--fontSize16);
                }
            }
        }
    }
    .modal {
        font-family: var(--primary-font) !important;
        #text-area {
            min-width: 640px;
            border: 1px solid lightgray;
            border-radius: 15px;
            font-size: var(--fontSize14);
            font-weight: var(--font-weight-regular);

            padding: 10px;
        }
        .btn {
            &.copy {
                background-color: var(--primary-color);
                color: var(--third-color);
                &:hover {
                    background-color: #4b3ee1;
                    color: var(--font-third-color);
                    font-size: var(--fontSize16);
                }
            }
            &.close {
                background-color: #0e0e0e;
                color: var(--font-third-color);
                &:hover {
                    background-color: #0e0e0e;
                    color: var(--font-third-color);
                    font-size: var(--fontSize16);
                }
            }
        }
    }

    #widget {
        .card {
            margin-bottom: 20px;
            .card-body {
                padding: 20px 40px;
            }
        }
    }
}
