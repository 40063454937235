
.input-text-box{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #C5C5C5;
    height: 38px;
    font-size: var(--fontSize14);
    padding: 0 0 0 15px;
    &::placeholder{
        font-size: var(--fontSize14);
        color: #C5C5C5 ;
    }
}
