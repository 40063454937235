.coming-call {
    position: fixed;
    top: 10px;
    left: -150px;
    z-index: 1000;

    transition: all 0.25s linear;
    opacity: 0;

    &.show {
        left: 120px;
        opacity: 1;
    }

    .card {
        box-shadow: 0 0 15px #66666650;
        overflow: hidden;
        border-radius: 2px 10px 10px 2px;
        border: 1px solid #ddd !important;

        .card-body {
            padding: 10px;
            display: flex;
            border-left: 10px solid var(--info-primary-color);

            .icon {
                width: 60px;
                height: 60px;
                border-radius: 10px;

                background-color: var(--info-secondary-color);
                display: flex;
                justify-content: center;
                align-items: center;

                &.chat {
                    background-color: var(--secondary-color);
                }

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            .actions {
                margin-left: 10px;

                h4 {
                    color: #3e3e3e;
                    font-size: 14px;
                    margin-bottom: 5px;
                }

                .button-groups {
                    display: flex;

                    button {
                        border-radius: 5px;
                        border: 0;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-size: 14px;
                        font-weight: 600;

                        img {
                            margin-right: 7px;
                        }

                        &.btn-primary {
                            margin-right: 10px;
                            background-color: #97d385;

                            img {
                                height: 16px;
                            }

                            &:hover {
                                background-color: darken($color: #97d385, $amount: 15);
                            }
                        }

                        &.btn-default {
                            margin-right: 10px;
                            background-color: #f2f2f2;
                            border: 1px solid #6e6e6e;
                            color: #6e6e6e;

                            &:hover {
                                background-color: darken($color: #f2f2f2, $amount: 15);
                            }
                        }

                        &.btn-warning {
                            background-color: #e13006;

                            img {
                                height: 22px;
                            }

                            &:hover {
                                background-color: darken($color: #e13006, $amount: 15);
                            }
                        }
                    }
                }
            }
        }
    }
}
