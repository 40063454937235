.visitors-animation {
    width: 100%;
    height: calc(100vh - 350px);
    display: none;
    border: 2px solid #9c9c9c;
    border-radius: 10px;
    background: #d3d3d3;
    padding: 20px;
    margin: 0px 0 30px 0;
    position: relative;
    // span {
    //     cursor: pointer !important;
    //     color: var(--font-secondary-color);
    //     position: absolute;
    //     right: 28px;
    //     top: 10px;
    // }
}

.visitor-screen-player {
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 54px;
        height: 31px;
    }
}
