$fontFamilyOpenSans: var(--primary-font);
$fontWeightDefault: 800;
.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    line-height: 1;
}
.badge-text-danger {
    color: #ec5857 !important;
    background-color: #f7d9d9 !important;
}
.badge-text-info {
    color: #867fd8 !important;
    background-color: #dcdaf1 !important;
}
.badge-text-success {
    color: #3fcb7e !important;
    background-color: #d0f0de !important;
}

.badge-text-success,
.badge-text-danger,
.badge-text-info {
    width: 95px;
    height: 24px;
    text-transform: capitalize;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 12px;
    font-weight: $fontWeightDefault;
    font-size: 12px;
    font-family: $fontFamilyOpenSans;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
}
