.container-date-picker-fully {
    width: 70%;
    font-size: var(--fontSize14);
    font-weight: var(--font-weight-regular);
    font-family: var(--primary-font);
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    position: relative;
    .dash{
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-semi-bold);
    }
    .first-section{
        margin: 0 0 1vh 0;
    }
    .second-section{
        margin: 0 0 0 0;
    }
   

    .container-date-picker-fully-option {
        display: flex;
        justify-content: space-between;
        
        padding: 0 5px 0 0;
        position: relative;
        .contianer-date-picker {
            position: absolute;
            top: 0;
            left: 89px;
            .input-date {
                width: 180px;
                height: 38px;
                border: 1px solid #c5c5c5;
                margin: 0 0 0px 0;
                padding: 0 0 0 15px;
                border-radius:  0 10px 10px 0;
                font-size: var(--fontSize14) !important;
                font-weight: var(--font-weight-regular) !important;
                font-family: var(--primary-font) !important;
                
            }
        }
        .contianer-time-picker {
            display: inline-flex;
            position: relative;
            .rc-time-picker-input{
                height: 38px;
                font-size: var(--fontSize14) !important;
                font-weight: var(--font-weight-regular) !important;
                font-family: var(--primary-font) !important;
                border-radius:10px  0 0 10px;
                color: black ;
                width: 90px;
            }
           
        }
    }
    // .container-date-picker-fully-option-endtime {
    //     display: flex;
    //     justify-content: space-between;
    //     padding: 0 5px 0 0;
    //     .end-date {
    //         position: relative;
    //         .input-end-date {
    //             width: 180px;
    //             height: 38px;
    //             border: 1px solid #c5c5c5;
    //             margin: 0 0 0px 0;
    //             padding: 0 0 0 15px;
    //             border-radius:  0 10px 10px 0;
    //             font-size: var(--fontSize14) !important;
    //             font-weight: var(--font-weight-regular) !important;
    //             font-family: var(--primary-font) !important;
    //         }
    //     }
    //     .end-time {
    //         display: inline-flex;
    //         position: relative;
    //         .rc-time-picker-input{
    //             height: 38px;
    //             font-size: var(--fontSize14) !important;
    //             font-weight: var(--font-weight-regular) !important;
    //             font-family: var(--primary-font) !important;
    //             border-radius:10px  0 0 10px;
    //             color: black ;
    //             width: 90px;
    //         }
    //     }
    // }
    .icon-calendar {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 38px;
        background-color: var(--primary-color);
        color: var(--fifth-color);
        cursor: pointer;
        text-align: center;
        border-radius: 0 10px 10px 0;
        padding: 2px 0 0 0;
    }


    @media only screen and (min-width: 768px){
        width: 70%;
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-regular);
        font-family: var(--primary-font);
        display: flex;
        justify-content: space-around;
        position: relative;
        .dash{
            font-size: var(--fontSize16);
            font-weight: var(--font-weight-semi-bold);
        }
        .first-section{
            margin: 0 0 0 0;
        }
        .second-section{
            margin: 0 0 0 0;
        }
    

        .container-date-picker-fully-option {
            display: flex;
            justify-content: space-between;
            padding: 0 5px 0 0;
            position: relative;
            .contianer-date-picker {
                position: absolute;
                top: 0;
                left: 89px;
                .input-date {
                    width: 180px;
                    height: 38px;
                    border: 1px solid #c5c5c5;
                    margin: 0 0 0px 0;
                    padding: 0 0 0 15px;
                    border-radius:  0 10px 10px 0;
                    font-size: var(--fontSize14) !important;
                    font-weight: var(--font-weight-regular) !important;
                    font-family: var(--primary-font) !important;
                    
                }
            }
            .contianer-time-picker {
                display: inline-flex;
                position: relative;
                .rc-time-picker-input{
                    height: 38px;
                    font-size: var(--fontSize14) !important;
                    font-weight: var(--font-weight-regular) !important;
                    font-family: var(--primary-font) !important;
                    border-radius:10px  0 0 10px;
                    color: black ;
                    width: 90px;
                }
            
            }
        }
        // .container-date-picker-fully-option-endtime {
        //     display: flex;
        //     justify-content: space-between;
        //     padding: 0 5px 0 0;
        //     .end-date {
        //         position: relative;
        //         .input-end-date {
        //             width: 180px;
        //             height: 38px;
        //             border: 1px solid #c5c5c5;
        //             margin: 0 0 0px 0;
        //             padding: 0 0 0 15px;
        //             border-radius:  0 10px 10px 0;
        //             font-size: var(--fontSize14) !important;
        //             font-weight: var(--font-weight-regular) !important;
        //             font-family: var(--primary-font) !important;
        //         }
        //     }
        //     .end-time {
        //         display: inline-flex;
        //         position: relative;
        //         .rc-time-picker-input{
        //             height: 38px;
        //             font-size: var(--fontSize14) !important;
        //             font-weight: var(--font-weight-regular) !important;
        //             font-family: var(--primary-font) !important;
        //             border-radius:10px  0 0 10px;
        //             color: black ;
        //             width: 90px;
        //         }
        //     }
        // }
        .icon-calendar {
            position: absolute;
            top: 0;
            right: 0;
            width: 42px;
            height: 38px;
            background-color: var(--primary-color);
            color: var(--fifth-color);
            cursor: pointer;
            text-align: center;
            border-radius: 0 10px 10px 0;
            padding: 2px 0 0 0;
        }
    }
    
}




*,
::after,
::before {
    box-sizing: border-box;
}
.react-datepicker__input-container{
    font-size: var(--fontSize14) !important;
    font-weight: var(--font-weight-regular) !important;
    font-family: var(--primary-font) !important;
}

.rc-time-picker-clear{
    top: 8px !important;
}
