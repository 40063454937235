.user-diconnected {
    // width: 100%;
    // height: 100%;
    z-index: 800000;

    position: fixed;
    top: 42%;
    left: 40%;


    .card {
        width: 300px;
        height: 150px;

        display: flex;
        justify-content: center;
        align-items: center;

        box-shadow: 0 1px 25px #00000025;

        h3 {
            font-size: 16px;
            font-weight: 600;
            color: #aaa;
            text-align: center;
            margin-top: 20px;
        }

      
    }
}
