// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 1s ease-in-out;
//   }
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

.weface-button-btn {
  background: #fff;
  color: #000;
  margin: 0 0 0 2px;
  border: 1px lightgray solid;
  font-weight: var(--font-weight-regular);
  font-size: var(--fontSize14);
  width: 145px;
  &:hover {
    background: var(--primary-color);
    color: #fff;
    margin: 0 0 0 2px;
    font-size: var(--fontSize14);
  }
}
.weface-title-table {
  text-align: initial;
  color: #000;
  font-weight: 600;
  p {
    font-size: var(--fontSize18);
    font-weight: var(--font-weight-semi-bold);
  }
}
.weface-btn-group {
  margin: 0 0 0 -18px;
}

.Toastify {
  font-size: var(--fontSize13);
  font-family: var(--primary-font);
  z-index: 0;
  .Toastify__toast-body {
    div + div {
      min-width: 225px;
    }
  }
}
.content-page {
  padding: 0 0;
  @media (min-width: 600px) {
    padding: 0 25px 0 25px;
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

.empty-placeholder {
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  h5 {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
