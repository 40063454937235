.page-toolbar {
    display: flex;
    width: 100%;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 7px 0 -1px var(--primary-color);
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;

    h4 {
        flex-grow: 1;
        font-size: 14px;
        margin-bottom: 0;
    }
    div.right {
        display: flex;
        h5 {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}
