h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

p {
    font-weight: 300;
}

code {
    font-family: var(--primary-font);
}

a {
    &:hover {
        color: var(--primary-color) !important;
    }
}

.text-warning {
    color: #ffcf48 !important;
}

.text-primary {
    color: var(--primary-color) !important
}