.container-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--primary-font);
    .title-calendar {
        margin: 0;
        padding: 0;
    }
   
    
    @media screen and (min-width: 1201px){
        .search-data {
            position: relative;
            width: calc(100% - 530px);
            .search-data-input {
                width: 100%;
                height: 38px;
                /* UI Properties */
                background: var(--background-secondary-color) 0% 0% no-repeat padding-box;
                border: 1px solid #f3f3f3;
                border-radius: 10px;
                opacity: 1;
                padding: 10px;
                font-size: var(--fontSize14);
                color: var(--font-secondary-color);
                &::placeholder {
                    font-size: var(--fontSize14);
                    color: var(--font-primary-color);
                }
            }
            .input-icons {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 5px;
                right: 0;
            }
        }
    }
    @media screen and (max-width: 1200px){
        .search-data {
            position: relative;
            width: calc(100% - 445px);
            .search-data-input {
                width: 100%;
                height: 38px;
                /* UI Properties */
                background: var(--background-secondary-color) 0% 0% no-repeat padding-box;
                border: 1px solid #f3f3f3;
                border-radius: 10px;
                opacity: 1;
                padding: 10px;
                font-size: var(--fontSize14);
                color: var(--font-secondary-color);
                &::placeholder {
                    font-size: var(--fontSize14);
                    color: var(--font-primary-color);
                }
            }
            .input-icons {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 5px;
                right: 0;
            }
        }
    }
    @media screen and (max-width: 1023px){
        .search-data {
            position: relative;
            width: calc(100% - 445px);
            .search-data-input {
                width: 100%;
                height: 38px;
                /* UI Properties */
                background: var(--background-secondary-color) 0% 0% no-repeat padding-box;
                border: 1px solid #f3f3f3;
                border-radius: 10px;
                opacity: 1;
                padding: 10px;
                font-size: var(--fontSize14);
                color: var(--font-secondary-color);
                &::placeholder {
                    font-size: var(--fontSize14);
                    color: var(--font-primary-color);
                }
            }
            .input-icons {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 5px;
                right: 0;
            }
        }
    }
    @media screen and (max-width: 800px){
        .search-data {
            position: relative;
            width: calc(100% - 315px);
            .search-data-input {
                width: 100%;
                height: 38px;
                /* UI Properties */
                background: var(--background-secondary-color) 0% 0% no-repeat padding-box;
                border: 1px solid #f3f3f3;
                border-radius: 10px;
                opacity: 1;
                padding: 10px;
                font-size: var(--fontSize14);
                color: var(--font-secondary-color);
                &::placeholder {
                    font-size: var(--fontSize14);
                    color: var(--font-primary-color);
                }
            }
            .input-icons {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 5px;
                right: 0;
            }
        }
    }
    @media screen and (max-width: 600px){
        .search-data {
            position: relative;
            width: calc(100% - 315px);
            .search-data-input {
                width: 100%;
                height: 38px;
                /* UI Properties */
                background: var(--background-secondary-color) 0% 0% no-repeat padding-box;
                border: 1px solid #f3f3f3;
                border-radius: 10px;
                opacity: 1;
                padding: 10px;
                font-size: var(--fontSize14);
                color: var(--font-secondary-color);
                &::placeholder {
                    font-size: var(--fontSize14);
                    color: var(--font-primary-color);
                }
            }
            .input-icons {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 5px;
                right: 0;
            }
        }
    }
}