.card-color-picker {
    margin-bottom: 0;
    .container-input-class {
        padding: 0;
        margin: 0;
        position: relative;

        button {
            background-color: transparent;
        }

        .input-class {
            width: 100%;
            border-bottom: 1px solid var(--border-secondary-color);
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            font-size: var(--fontSize14);
        }
        .base-colors {
            display: flex;
            justify-content: space-evenly;
            width: 170px;
            // top: 10px;
            // right: 10px;
            left: 777px;
            bottom: 257px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 20px;
                height: 20px;
                border-radius: 50%;

                cursor: pointer;

                .btn-icon {
                    width: inherit;
                    height: inherit;
                    padding: 0;
                    border: 2px solid;
                    border-radius: 50%;
                    svg {
                        color: #666;
                        margin-top: -8px;
                    }
                }

                &:last-of-type {
                    width: 45px;
                    border-radius: 10px;
                    border: 1px solid #ccc;
                    margin-left: 2px;

                 

                    button {
                        border: 0;
                        margin-top: 4px;
                    }
                    span {
                        min-width: 20px;
                        height: 20px;
                        border-radius: 50%;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
    .card {
        font-family: var(--primary-font);
        position: relative;
        z-index: 1;
        &.container-card-custom {
            padding: 18px 0 0 22px;
        }
       
    }
    .card-body {
        padding: 10px 0;
    }

    .title {
        color: var(--font-fourth-color);
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-bold);

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
    }
    .sub-title {
        color: var(--font-primary-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-semi-bold);
        margin-bottom: 30px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
    }

    .color-picker {
        position: absolute;
        // opacity: 0;d
        display: none;
        transition: all 0.15s linear;
        z-index: 100;
        bottom: -185px;
        left: 170px;
    
        .toolbar {
            display: flex;
            justify-content: flex-end;
            .btn {
                width: 30px;
                svg {
                    color: grey;
                }
            }
        }
    
        &.show {
            display: block;
        }

        .sketch-picker {
            label{
                width: unset;
            }
        }
    }
}
