.calendar-event {
    font-family: var(--primary-font) !important;
    width: 708px;
    height: 573px;
    border-radius: 20px;

    .calendar-title-event {
        width: 708px;
        height: 64px;
        border-radius: 15px 15px 0px 0px;
        background: var(--primary-color) 0% 0% no-repeat padding-box;
        padding: 15px 0px 0 0px;
        position: relative;
        .calendar-title-text-event {
            font-size: var(--fontSize24);
            font-weight: var(--font-weight-semi-bold);
            color: var(--font-third-color);
            margin: 0 0 0 30px;
        }
        .calendar-close-event {
            position: absolute;
            top: 15px;
            right: 30px;
            color: var(--font-third-color);
            font-weight: var(--font-weight-medium);
            cursor: pointer;
        }
    }
    .calendar-content-event {
        width: 708px;
        height: fit-content;
        background: var(--container-primary-color);
        padding: 15px 20px 55px 30px;
        border-radius: 0 0 15px 15px;

        .calendar-container-event {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 30px 0px 0 0px;
            
            label{
                color: var(--grey) !important;
                font-size: var(--fontSize16) !important;
                font-weight: var(--font-weight-regular) !important;
            }

            .calendar-left-event {
                width: 145px;
                height: 38px;
                margin-top: 3px;
                &.textarea{
                    height: 65px !important;
                    
                }
            }
            .calendar-right-event {
                width: 510px;
                height: 38px;
                &.button-action-event{
                    padding-top: 10px;
                }
                &.textarea{
                    height: 65px !important;
                }
                .error-message{
                    color: var(--danger-primary-color);
                    font-size: var(--fontSize13);
                    font-weight: var(--font-weight-semi-bold);
                    margin: 0;
                    padding: 0;
                }
                
            }
            .btn {
                &.save {
                    background-color: var(--primary-color);
                    color: var(--third-color);
                    font-size: var(--fontSize14);
                    float: right;
                    width: 153px;
                    height: 38px;
                    span + span{
                        margin: 0 0 0 10px;
                    }
                    // &:hover {
                    //     background-color: #4b3ee1;
                    //     color: var(--font-third-color);
                    //     font-size: var(--fontSize14);
                    // }
                }
       
            }
            .cancel-event {
                background-color: transparent;
                color: #1E1E1E;
                text-align: start;
                margin: 13px 0px 0 20px;
                font-size: 14px;
                font-weight: var(--font-weight-bold);
                cursor: pointer;
                span+span{
                    margin: 0 0 0 10px;
                }
            }
        }
    }
}

// .row-item {
//     margin: 20px 0 0 0;
// }

// #text-area {
//     width: 100%;
//     border: 1px solid lightgray;
//     border-radius: 15px;
//     font-size: var(--fontSize14);
//     font-weight: var(--font-weight-regular);

//     padding: 10px;
// }

//}
