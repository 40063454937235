.key-phrases {
    .body-content {
        line-height: 1.5;
        min-height: 155px !important;
        .paragraph-content {
            overflow-y: auto ;
            height: 95px;
        }
    }
}
