.iq-top-navbar,
.iq-sidebar {
    box-shadow: none;
}

.iq-top-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.top-nav-bar {
        width: calc(100% - 122px) !important;
        height: 65px;
        border-radius: 15px;
        margin: 15px;
        margin-left: 108px;
    }
}

.content-page {
    padding: 0;
    min-height: unset;
}
