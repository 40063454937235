$fontFamilyOpenSans: var(--primary-font);

.login-content {
    position: relative;
    padding-top: 200px;
    .forget-password-container {
        width: 460px;
        height: 446px;
        background-color: var(--background-secondary-color);
        border: 1px solid var(--background-secondary-color);
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
    .title-on-top {
        color: #1e1e1e;
        font-family: $fontFamilyOpenSans;
        font-size: var(--fontSize24);
        text-align: center;
        font-weight: var(--font-weight-semi-bold);
        margin-top: 45px;
        position: relative;
        .title-description {
            color: #1e1e1e;
            font-family: $fontFamilyOpenSans;
            font-size: var(--fontSize14);
            text-align: center;
            font-weight: var(--font-weight-regular);
            width: fit-content;
            position: absolute;
            top: 75px;
            left: 100px;
            line-height: 1.5;
        }
    }
    .forget-password-email-box-container {
        position: relative;
        .forget-password-email-box {
            position: absolute;
            top: 135px;
            left: 33px;
        }
    }
    .submit-button-container {
        position: relative;
        .button-send-email {
            position: absolute;
            width: 392px;
            height: 33px;
            background: var(--primary-color);
            border-radius: 7px;
            border: 0px;
            color: white;
            top: 240px;
            left: 32px;
            &:hover {
                background: var(--primary-color);
            }
            font-size: 14px;
            font-family: $fontFamilyOpenSans;
            font-weight: var(--font-weight-semi-bold);
        }
    }
    .back-to-login-container {
        position: relative;
        .back-to-login {
            position: absolute;
            top: 285px;
            left: 180px;
            font-size: var(--fontSize14);
            font-family: $fontFamilyOpenSans;
            font-weight: var(--font-weight-bold);
            color: var(--font-secondary-color);
        }
    }
}
