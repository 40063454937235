.rrweb-loading-container {
    width: 100%;
    height: 100%;
    z-index: 800000;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000090;

    .rrweb-loading-wrapper {
        width: 350px;
        height: auto;
        border-radius: 10px;
        color: #444;
        padding: 40px 20px 40px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: 600;
            color: #ffffff;
            margin-top: 20px;
        }
        h4 {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: 600;
            color: #ffffff;
        }
    }
}
