.input-box-search {
    .input-service-search {
        margin: 16px 0 0 0;
        border-radius: 10px !important;
        height: 38px;
        border: 1px solid var(--border-secondary-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-regular);
        width: 360px;
        position: relative;
    }
    .input-service-search-icon {
        height: 38px;
        margin: 16px 0 0 -20px;
        z-index: 20;
        position: absolute;
        top: 0;
        left: 340px;
        
    }
    
    div[class^="col-"] {
        padding-left: 0;
    
    }
    
    .search-icon-card {
        width: 66px;
        height: 66px;
        margin: 0px 0 0 -8px;
    }
    .search-title-text {
        color: var(--fourth-color);
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-semi-bold);
        margin: 20px 0 0 -18px;
    }
}
