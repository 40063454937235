.insight-detail {
    .card {
        height: unset;
        margin-bottom: 25px;

        @media (min-width: 600px) {
            height: 160px;
        }

        &.title {
            height: unset;
            padding-bottom: 0;
        }

        .card-body {
            h4 {
                font-size: 14px;
                font-weight: 700;
                color: #444;
                margin-bottom: 0;
                line-height: 1.5;
            }
            h5 {
                font-size: 13px;
                display: flex;
                line-height: 1;
                .caption {
                    font-weight: 700;
                    white-space: nowrap;
                    min-width: 40px;
                    margin-right: 20px;
                    color: #555;
                }
                span {
                    font-weight: 700;
                    color: #444;
                }
            }
            span {
                color: #999;
                font-size: 12px;
                font-weight: 600;
            }

            p {
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 8px;
                color: #444;
            }

            .table-call-info {
                tbody {
                    tr {
                        td {
                            font-size: 13px;
                            padding: 6px;
                            line-height: 1;
                            font-weight: 700;
                            color: #444;
                            &:first-of-type {
                                width: 100px;
                            }
                        }

                        &:first-of-type {
                            td {
                                padding-top: 0;
                            }
                        }
                    }
                }
            }
        }

        .button-group {
            border: 0;
            .btn {
                margin-right: 10px;
            }
        }

        .btn {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 1;
            height: 34px;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 5px;
                margin-top: -3px;
            }

            &.btn-primary {
                background-color: var(--primary-color);
                border: 0;
            }
            &.btn-sm {
                height: 32px;
                width: 84px;
            }
            &:hover {
                font-size: inherit;
            }
        }

        &.chart {
            height: unset;
            @media (min-width: 600px) {
                height: 280px;
            }

            .border-right {
                border-right: 0 !important;
                @media (min-width: 600px) {
                    border-right: 3px solid #eee !important;
                }
            }

            .chart-wrapper {
                margin: 20px 0;

                @media (min-width: 600px) {
                    margin-bottom: 0;
                }

                display: flex;
                justify-content: center;
            }

            .total-score {
                @media (min-width: 600px) {
                    h4,
                    h5 {
                        margin-left: 50px;
                    }
                }
            }
        }
    }
}
