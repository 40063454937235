$fontFamilyOpenSans: var(--primary-font);
.login-page{
    .auth-card {
        min-height: unset !important;
        padding-top: 45px;
        padding-bottom: 40px;
        .auth-content {
            min-height: unset !important;
        }
    }
    
    .bg-login {
        background: #fafafa no-repeat center center / cover;
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: hidden;
    }
    
    .logo {
        background: no-repeat;
        width: 180px;
        height: 100px;
        position: absolute;
        top: 46px;
        left: 64px;
    }
    
    @media only screen and (max-width: 991px) and (min-width: 480px) {
        .logo {
            // margin: 13px 0 0 -10px;
        }
    }
    
    @media only screen and (min-width: 1024px) {
        .logo {
            // margin: 44px 0 0 -85px;
        }
    }
    
    .input-login-auth {
        box-sizing: border-box;
        position: relative;
        width: 396px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        margin: 0 auto;
    }
    .remember-radio {
        // margin: 0 0 0 -47px;
    }
    .remember-radio-text {
        // font-weight: 600;
        // font-size: 14px;
        // width: 109px;
        font-weight: var(--font-weight-regular);
        font-size: 14px;
        width: 109px;
        font-family: $fontFamilyOpenSans;
    }
    .button-login {
        position: relative;
        width: 392px;
        height: 33px;
        background: var(--primary-color);
        border-radius: 7px;
        border: 0px;
        color: white;
        margin: 30px 0 0 0;
        // font-size: 14px;
    
        &:hover {
            background: var(--primary-color);
        }
        font-size: 14px;
        font-family: $fontFamilyOpenSans;
        font-weight: var(--font-weight-regular);
    }
    .button-login:hover {
        background: #5044d3;
        font-size: 15px;
    }
    
    .forgot-password {
        font-size: 14px;
        color: #1e1e1e !important;
        font-weight: 600;
        text-decoration: none;
        text-align: center;
        color: black !important;
        text-decoration: none;
        font-size: 14px;
        font-family: $fontFamilyOpenSans;
        font-weight: var(--font-weight-regular);
    }
    .forgot-password-container {
        // width: 392px;
    }
    .title-text-top {
        color: #1e1e1e;
        font-family: $fontFamilyOpenSans ;
        font-size: var(--fontSize24) ;
        text-align: center;
    }
    .title-text-top-container {
        // width: 429px;
        padding: 0 20px;
        color: black !important;
        font-size: 24px;
        font-family: $fontFamilyOpenSans;
        font-weight: var(--font-weight-semi-bold);
    }
    .title-text-top-container {
        width: 429px;
        font-family: $fontFamilyOpenSans;
    }
    .card-container {
        width: 460px;
    }
    
    .password-container {
        position: relative;
    }
    .bi-eye,
    .bi-eye-slash {
        position: absolute;
        top: 15%;
        right: 4%;
        cursor: pointer;
        color: #000;
    }
    
    .container-form-login{
        position: relative;
        padding: 0 0 0 0;
        .invalid-alert{
            font-size: var(--fontSize12);
            font-weight: var(--font-weight-regular);
            font-family: $fontFamilyOpenSans;
            color: rgb(247, 19, 19);
            position: absolute;
            top: 32px;
            left: 17px;
        }
    }
    
}
