$primaryColor: var(--primary-color);
.rbc-overlay{
    z-index: 10 !important;
}
.container-fluid {
    z-index: 10;
    position: relative;
   
    .container-option-top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .sales-agent {
            width: 400px;
            label {
                font-size: 13px;
                font-weight: 700;
                color: #444;
                margin-bottom: 0;
                margin-left: 5px;
            }
            select {
                border-color: #e4e4e4 !important;
            }
        }
        .buttonGroup-option {
            width: calc(100% - 400px);
        }
    }
    .container-calendar {
        width: 100%;
        padding: 30px 0;
        position: relative;

        .rbc-toolbar {
            margin-bottom: 15px;

            .rbc-btn-group {
                position: absolute;
                left: 0;
                button {
                    border-radius: 0 !important;
                    background-color: #fff;
                    border-color: #e4e4e4;
                    &:first-of-type {
                        border-radius: 10px 0 0 10px !important;
                    }
                    &:last-of-type {
                        border-radius: 0 10px 10px 0 !important;
                    }

                    &:hover {
                        background: var(--primary-color);
                        color: #fff !important;
                    }
                }
            }

            .rbc-toolbar-label {
                font-weight: 700;
            }
        }

        .rbc-month-view {
            border: 0px !important;
            background-color: var(--font-third-color);

            .rbc-month-row {
                border-top: 1px solid #eee;
                .rbc-row-bg {
                    .rbc-off-range-bg {
                        background: #f7f8f9;
                    }
                    .rbc-today {
                        background-color: #e8e6f5;
                    }
                }
            }

            .rbc-day-bg {
                border-left: 0px !important;
            }
            .rbc-header {
                border: 0px !important;
                padding: 8px;
                text-transform: uppercase !important;
                span {
                    font-weight: var(--font-weight-bold) !important;
                }
            }

            .rbc-event {
                background-color: #dfdef5 !important;
                color: #9590dd !important;
                padding: 0 5px 0 5px  !important;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0px;
                    height: 30px;
                    width: 4px;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    background-color: var(--primary-color);
                }
                .event-container{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    width: fit-content;
                    height: 30px;
                    
                    .title-event{
                        font-size: var(--fontSize14);
                        font-weight: var(--font-weight-bold);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        position: absolute;
                        top: 0;
                        left: 7px;
                        width: 180px;
                       
                    }
                    .title-start-time{
                        font-size: var(--fontSize14);
                        font-weight: var(--font-weight-bold);
                        float: right ;
                        margin: 0 5px 0 0;
                        position: absolute;
                        top: 0;
                        left: 195px;
                    }
                }
              
            }
        }
        .rbc-btn-group {
            .rbc-active {
                width: 75px;
                height: 38px;
                /* UI Properties */
                background: var(--primary-color) 0% 0% no-repeat padding-box;
                background: #6259ce 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: var(--font-third-color) !important;
                opacity: 1;
                font-size: var(--fontSize14);
                font-weight: var(--font-weight-regular);
            }
        }

        .rbc-row-segment {
            z-index: 10 !important;
        }

        .rbc-row-content {
            .rbc-date-cell {
                text-align: left;
                button {
                    font-weight: 600;
                    text-align: left;
                    padding: 0 15px;
                }
            }
        }
    }
    .rbc-toolbar button {
        width: 75px !important;
        height: 38px !important;
        /* UI Properties */
        border-radius: 10px !important;
        color: var(--font-secondary-color) !important;
        font-size: var(--fontSize14) !important;
        font-weight: var(--font-weight-regular) !important;
    }
}
.modal-content {
    width: fit-content;
    height: fit-content;
    border-radius: 20px;
}
.popup-event {
    width: 474px;
    height: 273px;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    margin: 0;
    // background-color: var(--background-secondary-color);
}
.rbc-event {
    background-color: #dfdef5 !important;
    color: #9590dd !important;
    padding: 0 5px 0 5px  !important;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0px;
        height: 30px;
        width: 4px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: var(--primary-color);
    }
    .event-container{
        position: relative;
        display: flex;
        justify-content: center;
        width: fit-content;
        height: 30px;
        
        .title-event{
            font-size: var(--fontSize14);
            font-weight: var(--font-weight-bold);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            position: absolute;
            top: 0;
            left: 7px;
            width: 180px;
           
        }
        .title-start-time{
            font-size: var(--fontSize14);
            font-weight: var(--font-weight-bold);
            float: right ;
            margin: 0 5px 0 0;
            position: absolute;
            top: 0;
            left: 290px;
        }
    }
  
}