.btn-view-all {
    color: #333;
    font-weight: 700;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
 
    position: relative;

    &::after {
        content: '';
        width: 1px;
        height: 1.5px;
        opacity: 0;
        background-color: #6e6e6e;
        position: absolute;
        bottom: 5px;
        margin-left: 15px;
        transition: all 0.15s ease-out;
    }

    &:hover {
        background: transparent;
        color: inherit;
        
        &::after {
            opacity: 1;
            transform:scaleX(95);
        }
    }

    svg {
        margin-left: 5px;
        margin-top: 2px;
    }
}