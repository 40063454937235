.invitation-link {
    padding: 0 0 0 13px;
    .send-invitation-text {
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-bold);
    }

    .card-body {
        padding: 18px 16px;

        .title {
            margin-top: 5px;
            margin-bottom: 22px;
            font-weight: var(--font-weight-semi-bold);
            color: var(--grey);
            font-size: var(--fontSize14);
        }

        .form {
            display: flex;
            justify-content: space-between;
            .input-group {
                display: flex;
                justify-content: flex-end;

                input[type="text"] {
                    box-sizing: border-box;
                    height: 38px;
                    background: var(--background-secondary-color);
                    border: 1px solid var(--border-secondary-color);
                    border-top-left-radius: 9px;
                    border-bottom-left-radius: 9px;
                    padding: 12px 10px;
                    font-size: 10px;
                    margin-left: 5px;
                    width: calc(100% - 75px);
                }

                button[type="submit"] {
                    width: 63.14px;
                    height: 38px;
                    background: var(--primary-color);
                    border: 0;
                    border-top-right-radius: 9px;
                    border-bottom-right-radius: 9px;
                    color: var(--fifth-color);
                    font-size: 12px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    padding: 0;

                    // &:hover {
                    //     margin: 20px 0 34px 140px;
                    //     padding: 0 0 0 0;
                    //     position: absolute;
                    //     background-color: var(--sixth-color);
                    // }
                    // span {
                    //     font-size: var(--fontSize12);
                    //     color: var(--third-color);
                    //     margin: -28px 0 0 -14px;
                    //     position: absolute;
                    // }
                }
            }
        }
    }
    

 

    // .send-invitation-link-contrainer-internal {
    //     margin: -23px 0 0 -45px;
    // }
    // .flexContainer {
    //     display: flex;
    //     width: 145px;
    // }
    // .input-send-invitation-link {
    //     box-sizing: border-box;
    //     width: 135.14px;
    //     height: 33px;
    //     background: var(--background-secondary-color);
    //     border: 1px solid var(--border-secondary-color);
    //     border-top-left-radius: 9px;
    //     border-bottom-left-radius: 9px;
    //     padding: 5px;
    //     font-size: 14px;
    //     margin: 21px 0 0 5px;
    //     flex: 1;
    // }
    // .input-send-invitation-info-button {
    //     width: 63.14px;
    //     height: 34px;
    //     background: var(--primary-color);
    //     border: 1px solid var(--border-secondary-color);
    //     border-top-right-radius: 9px;
    //     border-bottom-right-radius: 9px;
    //     margin: 20px 0 34px 140px;
    //     padding: 0 0 0 0;
    //     position: absolute;
    //     &:hover {
    //         margin: 20px 0 34px 140px;
    //         padding: 0 0 0 0;
    //         position: absolute;
    //         background-color: var(--sixth-color);
    //     }
    //     span {
    //         font-size: var(--fontSize12);
    //         color: var(--third-color);
    //         margin: -28px 0 0 -14px;
    //         position: absolute;
    //     }
    // }
    // .button-send-invitation {
    //     width: 75px;
    //     height: 33px;
    //     color: var(--fourth-color);
    //     border-radius: 9px;
    //     margin: 0 0 0 0;
    //     border: 1px solid var(--border-secondary-color);
    //     background: var(--background-secondary-color);
    //     &:hover {
    //         background: #6259ce;
    //         border: 1px solid var(--third-color);
    //         color: var(--font-third-color);
    //     }
    //     &.active-event {
    //         background: #6259ce;
    //         border: 1px solid var(--third-color);
    //         color: var(--font-third-color);
    //     }
    //     span {
    //         padding: 0 0 0 0;
    //         position: absolute;
    //         margin: -28px 0 0 -18px;
    //         text-align: center;
    //         background: var(--background-secondary-color);
    //         font-size: 14px;
    //         height: 0;
    //     }
    // }

    // .button-container {
    //     width: 75px;
    //     height: 40px;
    //     border-radius: 9px;
    //     margin: 0 auto;
    //     padding: 6px 0 0 19px;
    //     cursor: pointer;
    // }
}
