#container-loading {
    width: 100%;
    min-height: 400px;
    height: 100%;
    margin: -100px 0 0 0;
    //top: 600px;
    position: fixed;
    z-index: 8888;
    backdrop-filter: blur(5px);
    
    #loading-v3{
      background: url('../../../public/assets/icons/loading_v3-100.gif');
      height: 100px;
      width: 100px;
      position: absolute;
      top: 330px;
      left: 50%;
      z-index: 9999;
    }
  }
  