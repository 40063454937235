.suggest-card-container {
    height: 320px;
    align-items: center;
    position: relative;
    .container-suggest {
        width: 420px;
        align-items: center;
        position: relative;
        .suggest-title {
            font-size: var(--fontSize24);
            font-weight: var(--font-weight-bold);
            padding: 0 0 70px 0;
            text-align: center;
        }
        .suggest-img {
            padding: 0;
            margin: 0;
            width: 82px;
            height: 82px;
        }
        .suggest-caption {
            font-size: var(--fontSize13);
            font-weight: var(--font-weight-regular);
            padding: 0 0 10px 0;
            line-height: 1.4;
            text-align: center;
            width: 345px;
            position: absolute;
            bottom: -75px;
            left: 42px;
        }
    }
}

// .container-suggest{
//     width: 100%;
//     align-items: center;
//     position: relative;
// }
// .suggest-title{
//     font-size: var(--fontSize26);
//     font-weight: var(--font-weight-bold);
//     padding: 0 0 70px 0;
//     text-align: center;
// }
// .suggest-img{
//     padding: 0;
//     margin: 0;
//     width: 82px;
//     height: 82px;
// }
// .suggest-caption{
//     font-size: var(--fontSize13);
//     font-weight: var(--font-weight-regular);
//     padding: 0 0 10px 0;
//     line-height: 1.4;
//     text-align: center;
// }
