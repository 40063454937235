.video-container {
    position: fixed;
    top: 123px;
    right: 46px;
    z-index: 1000;
    width: 320px;
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ddd !important;

    .remote-video {
        z-index: 1;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .local-video {
        position: absolute;
        width: 100px;
        height: 64px;
        bottom: 5px;
        right: 5px;
        border-radius: 10px;
        overflow: hidden;
        z-index: 2;
    }

    video {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .flipped-x {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        top: 0;
        left: 0;
    }
}
