.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
    &.justify-between {
        justify-content: space-between;
    }
    

    &.align-end {
        align-items: flex-end;
    }
}