.card-input-text {
    .container-input-class {
        padding: 0;
        margin: 0;
        position: relative;

        .input-class {
            width: 90%;
            border-bottom: 1px solid var(--border-secondary-color);
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            font-size: var(--fontSize14);
            font-weight: var(--font-weight-semi-bold);
        }
        span {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
    .card {
        font-family: var(--primary-font);
        position: relative;
        z-index: 1;
        &.container-card-custom {
            height: 189px;
            padding: 18px 0 0 22px;
        }
    }

    .title {
        color: var(--font-fourth-color);
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-bold);
    }
    .sub-title {
        color: var(--font-primary-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-semi-bold);
        margin-bottom: 30px;
    }
}
