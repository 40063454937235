@media screen and (min-width: 1524px) {
    .trigger-app {
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 15px;
            tbody {
                tr {
                    cursor: pointer;
    
                    &:hover {
                        td {
                            background: #f4f4f7;
                        }
                    }
    
                    td {
                        background: #fff;
                        font-family: var(--primary-font);
                        padding: 15px 0;
                        &:last-of-type {
                            text-align: right;
                        }
                    }
    
                    td:first-child {
                        padding-left: 25px;
                        border-radius: 10px 0 0 10px;
                    }
    
                    td:last-child {
                        padding-right: 25px;
                        border-radius: 0 10px 10px 0;
                    }
                }
            }
        }
        .info {
            margin: 0 0 0 138px;
            .info-item {
                display: flex;
                align-items: flex-start;
                label {
                    font-size: 12px;
                    width: 80px;
                    font-weight: 700;
                    color: var(--primary-color);
                    margin-bottom: 0;
                    line-height: 2;
                }
                span {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 2;
                    
                    
                }
            }
        }
    }
}
@media screen and (max-width: 1440px) {
.trigger-app {
    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 15px;
        tbody {
            tr {
                cursor: pointer;

                &:hover {
                    td {
                        background: #f4f4f7;
                    }
                }

                td {
                    background: #fff;
                    font-family: var(--primary-font);
                    padding: 15px 0;
                    &:last-of-type {
                        text-align: right;
                    }
                }

                td:first-child {
                    padding-left: 25px;
                    border-radius: 10px 0 0 10px;
                }

                td:last-child {
                    padding-right: 25px;
                    border-radius: 0 10px 10px 0;
                }
            }
        }
    }
    .info {
        margin: 0 0 0 138px;
        .info-item {
            display: flex;
            align-items: flex-start;
            label {
                font-size: 12px;
                width: 80px;
                font-weight: 700;
                color: var(--primary-color);
                margin-bottom: 0;
                line-height: 2;
            }
            span {
                font-weight: 700;
    font-size: 12px;
    line-height: 2;
    width: 260px;

    // white-space: nowrap;
    // width: 195px;
    // overflow: hidden;
    // text-overflow: ellipsis;
                
                
            }
        }
    }
}

}

@media screen and (max-width: 1200px){
    .trigger-app {
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 15px;
            tbody {
                tr {
                    cursor: pointer;
                    height: 135px;
    
                    &:hover {
                        td {
                            background: #f4f4f7;
                        }
                    }
    
                    td {
                        background: #fff;
                        font-family: var(--primary-font);
                        padding: 15px 0;
                        &:last-of-type {
                            text-align: right;
                        }
                        position: relative;
                        width: 40%;
                    }
    
                    td:first-child {
                        padding-left: 25px;
                        border-radius: 10px 0 0 10px;
                    }
    
                    td:last-child {
                        padding-right: 25px;
                        border-radius: 0 10px 10px 0;
                    }
                }
            }
        }
        .info {
            position: absolute;
            top: 5px;
            left: -110px;
            width: 640px;
            .info-item {
                display: flex;
                align-items: flex-start;
                position: relative;
                &.period{
                    margin: 52px 0 0 0;
                }
                label {
                    font-size: 12px;
                    width: 80px;
                    font-weight: 700;
                    color: var(--primary-color);
                    margin-bottom: 0;
                    line-height: 2;
                }
                span {
                    font-weight: 700;
                    font-size: 12px;
                    width: 260px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* number of lines to show */
                            line-clamp: 3; 
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

}

@media screen and (max-width: 1024px){

    .trigger-app {
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 15px;
            tbody {
                tr {
                    cursor: pointer;
    
                    &:hover {
                        td {
                            background: #f4f4f7;
                        }
                    }
    
                    td {
                        background: #fff;
                        font-family: var(--primary-font);
                        padding: 15px 0;
                        &:last-of-type {
                            text-align: right;
                        }
                        position: relative;
                        width: 45%;
                    }
    
                    td:first-child {
                        padding-left: 25px;
                        border-radius: 10px 0 0 10px;
                    }
    
                    td:last-child {
                        padding-right: 25px;
                        border-radius: 0 10px 10px 0;
                    }
                }
            }
        }
        .info {
            position: absolute;
            top: 5px;
            left:-134px;
            .info-item {
                display: flex;
                align-items: flex-start;
                position: relative;
                &.period{
                    margin: 10px 0 0 0;
                }
                label.period {
                    font-size: 12px;
                    width: 80px;
                    font-weight: 700;
                    color: var(--primary-color);
                    margin-bottom: 0;
                    line-height: 2;
                    position: absolute;
                    bottom: -12px;
                    left: 0px;

                }
                span.period {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 2;
                    position: absolute;
                    top: 15px;
                    width: max-content;
                    left: 0;
                }
            }
        }
        button.btn.btn-icon.user-delete{
            position: absolute;
            bottom: 50px;
            right: 70px;
    }
    }
}
@media screen and (max-width: 1000px){

    .trigger-app {
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 15px;
            tbody {
                tr {
                    cursor: pointer;
    
                    &:hover {
                        td {
                            background: #f4f4f7;
                        }
                    }
    
                    td {
                        background: #fff;
                        font-family: var(--primary-font);
                        padding: 15px 0;
                        &:last-of-type {
                            text-align: right;
                        }
                        position: relative;
                        display: grid;
                        width: 100%;
                        margin: 0 auto;
                    }
    
                    td:first-child {
                        padding-left: 25px;
                        border-radius:10px;
                        width: 100%;
                    }
    
                    td:last-child {
                        padding : 32px;
                        border-radius:10px;
                        width: 100%;
                    }
                }
            }
        }
        .info {
            position: absolute;
            top: 5px;
            left: 85px;
            z-index: 10;
            .info-item {
                display: flex;
                align-items: flex-start;
                position: relative;
                &.period{
                    margin: 30px 0 0 0;
                }
                label.period {
                    font-size: 12px;
                    width: 80px;
                    font-weight: 700;
                    color: var(--primary-color);
                    margin-bottom: 0;
                    line-height: 2;
                    position: absolute;
                    top: 20px;
                    left: 0px;

                }
                span {
                    white-space: nowrap; 
                    width: 195px; 
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span.period {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 2;
                    position: absolute;
                    top: 20px;
                    width: max-content;
                    left: 80px;
                }
            }
        }
        button.btn.btn-icon.user-delete{
                position: absolute;
                bottom: 10px;
                right: 0;
        }
    }

}


