@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@500&display=swap');

// template ui
@import "./assets/scss/variable";
@import "./assets/scss/base";
@import "./assets/scss/layout";
@import "./assets/scss/typography";
@import "./assets/scss/utils";

// components
@import "./assets/scss/tab";
@import "./assets/scss/badge";
@import "./assets/scss/card";
@import "./assets/scss/table";
@import "./assets/scss/form";


code {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10000;

    background: #eee;
    color: #cf3838;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    padding: 20px;
    line-height: 16px;
}
