:root {
    --primary-font: "Open Sans", sans-serif;
    --secondary-font: "Roboto";

    
    --font-weight-regular : 400;
    --font-weight-medium : 500;
    --font-weight-semi-bold : 600;
    --font-weight-bold : 700;


    --primary-color: #6259ce;
    --primary-color-darker: #5049a5;
    --secondary-color: #dcdaf1;
    --third-color: #e7fafc;
    --fourth-color: #2e2e2e;
    --fifth-color: #ffffff;
    --sixth-color: #8983d7;


    --danger-primary-color: #ed6160;
    --danger-secondary-color: #f7d9d9;
    --danger-third-color: #fbe5e4;
    --info-primary-color: #63e2f1;
    --info-secondary-color: #e7fafc;
    --success-primary-color: #3dcb7d;
    --success-secondary-color: #def6e9;

    --font-primary-color: #888888;
    --font-secondary-color: #1c1c1c;
    --font-third-color: #ffffff;
    --font-fourth-color : #1E1E1E;
    --font-fifth-color : #666666;
    --font-sixth-color : #B7B6B6;

    --disabled-text-color: #dfdfdf;

    --background-primary-color: #fafafa;
    --background-secondary-color: #ffffff;
    
    --border-primary-color: #ffffff;
    --border-secondary-color: lightgray;

    --container-primary-color: #fafafa;

    --grey: #656565;
    --grey-dark: #1c1c1c;

    --fontSize12: 12px;
    --fontSize13: 13px;
    --fontSize14: 14px;
    --fontSize15: 15px;
    --fontSize16: 16px;
    --fontSize18: 18px;
    --fontSize20: 20px;
    --fontSize22: 22px;
    --fontSize24: 24px;
    --fontSize26: 26px;
    --fontSize28: 28px;
    --fontSize30: 30px;
    --fontSize50: 50px;

}
