@import "./variable";
body {
    margin: 0;
    font-family: var(--primary-font);
    font-size: var(--font-size);
    color: var(--grey-dark);
    font-weight: var(--font-weight-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f4f796;
}
button, span, input, div{
    font-family: var(--primary-font);
    font-size: var(--font-size);
    font-weight: var(--font-weight-regular);
}

