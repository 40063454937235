.pagination {
    display: flex;
    justify-content:space-around;
    list-style: none;
    padding-top: 15px;
    float: right;
  }
  
  .page-link {
    display: block;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 2px;
    text-decoration: none;
  }
  
  a.page-link:hover {
    background-color: #cccccc;
  }
  
  .page-item.active .page-link {
    color: #ffffff;
    border: 1px var(--primary-color) solid;
    background-color: var(--primary-color) !important;
  }