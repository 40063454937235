$fontWeightDefault: 600;

@media (min-width: 1300px) {
  body.sidebar-main .content-page {
    margin-left: 82px;
  }
  .navbar-list li {
    margin: 0 0 0 15px !important;
  }
}

body.sidebar-main .content-page {
  margin-left: 82px;
}

@media (min-width: 1299px) {
  .navbar-list li {
    margin: 0 0 0 15px !important;
  }
}

@media (max-width: 1299px) {
  .navbar-list li {
    margin: 0 0 0 15px !important;
  }
}

@media (max-width: 575px) {
  .lable-switch-off-on {
    margin: 24px 0 0 56px !important;
    position: absolute;
  }
  .navbar-list li {
    margin: 0 0 0 0 !important;
  }
}

.icon-weface {
  width: 50px;
  height: 50px !important;
  cursor: pointer;
}
.warpmenu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

body.sidebar-main .iq-sidebar {
  width: 90px;
}

.caption-menu-left {
  display: flex !important;
  position: absolute !important;
  margin: 0 auto !important;
  top: 37px;
  font-size: 11px;
}
.sidebar-default .iq-sidebar-menu .iq-menu > li {
  &.active {
    > a {
      color: var(--primary-color) !important;
    }
  }

  > a {
    padding: 10px 0 30px;
   
    font-weight: $fontWeightDefault;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #aaa !important;
    }
  }
}

.sidebar-default .iq-sidebar-menu .iq-menu > li > a {
  color: #d3d3d3 ;
}

.container-menu-left-text {
  margin-top: 10px;
  font-weight: $fontWeightDefault;
}
