.card-weface-script {
    button {
        background-color: var(--primary-color);
        color: var(--font-third-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-regular);
        border-radius: 15px;
        width: 109px;
        height: 38px;
        border: 0px;
        padding: 0 0 0 16px;
        position: relative;
        &:hover {
            background-color: #392db8;
            font-size: var(--fontSize14);
            color: var(--font-third-color);
        }
    }
    .container-copy-script {
        padding: 0;
        margin: 0;
        position: relative;
    }
    .iconCopy {
        position: absolute;
        top: 5px;
        left: 12px;
    }
    .card {
        font-family: var(--primary-font);
        position: relative;
        z-index: 1;
        &.container-card-custom {
            height: 189px;
            padding: 18px 0 0 22px;
        }
    }

    .title{
        color: var(--font-fourth-color);
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-bold);
    }
    .sub-title {
        color: var(--font-primary-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-semi-bold);
        margin-bottom: 30px;
    }
}
