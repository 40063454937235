.service-call {
    margin-bottom: 15px;
    width: 100%;

    @media only screen and (min-width: 768px){
        &.col-sm-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
      }
   
    @media only screen and (min-width: 1024px) and (max-width: 1440px){
        &.col-md-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
      }
      @media only screen and (min-width: 1440px) and (max-width: 1920px){
        &.col-lg-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
      }
      @media only screen and (min-width: 1920px){
        &.col-lg-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
      }
    
    .card-header {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        border-bottom: 0;

        img {
            height: 36px;
            margin-left: -5px;
        }

        h4 {
            color: #333;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0;
            margin-left: 5px;
        }
    }

    .card-body {
        padding-top: 0;
        padding-bottom: 10px;
        .widgets {
            display: flex;
            justify-content: space-around;
            margin-bottom: 15px;
            div {
                text-align: center;
                span {
                    margin-left: 5px;
                    font-size: 11px;
                }
            }
        }

        p {
            line-height: 1.5;
            margin-bottom: 0;
        }

        .caption-clip-card {
            span {
                color: #333;
                font-size: 12px;
            }
        }

        .read-more {
            margin-left: 30%;
            display: flex;
            align-items: center;
            font-size: 11px;
            font-weight: 700;
            cursor: pointer;

            svg {
                margin-top: 1px;
                margin-left: 2px;
            }
        }
    }
}