.session-view-container {
    background: linear-gradient(0deg, #202747, #202747), #0e1019;
    flex: 1;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .rrweb-wrapper {
        // width: 100%;
        // height: calc(100vh - 350px);
        // display: none;
        // border: 2px solid #9c9c9c;
        // border-radius: 10px;
        // background: #d3d3d3;
        // padding: 0;
        // margin: 0px 0 30px 0;
        // position: relative;
        // overflow: hidden;

        flex: 1;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: padding-bottom 0.3s;

        .rrweb {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            .rrwebPlayer {
                overflow: hidden;
                margin: auto;
                box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
                background: #ffffff;

                &.--uninteractive {
                    pointer-events: none;
                }

                &.--hide-mouse {
                    display: none;
                }

                .replayer-wrapper {
                    position: relative;
                    margin: auto;
                    transition: 0.1s;
                    overflow: hidden;
                    user-select: none;

                    &::before {
                        content: "";
                        position: absolute;
                        top: -100px;
                        left: -100px;
                        right: -100px;
                        bottom: -100px;
                        z-index: 2;
                        background: rgba(30, 39, 73, 0.54);
                        transition: opacity 0.3s;
                        animation: away-overlay-fade-in 0.3s ease;
                    }

                    .replayer-mouse {
                        background-image: url(https://app.servicebell.com/assets/mouse-cursor.6dee37f1.png);
                        background-position: center;
                        width: 24px;
                        height: 24px;
                        transition: left 70ms linear, top 70ms linear;
                        margin: 0;
                        border: none;
                        border-radius: 0;
                    }
                }
            }

            .widgetRrweb {
                position: absolute;
                top: 50%;
                left: 50%;
                pointer-events: none;
                transform: translate(-50%, -50%);
            }
        }
    }
}
