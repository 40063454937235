button.btn-live-control {
    width: 64px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 10px;
    padding: 2px 0 2px 0 !important;
    border-radius: 5px !important;
    border-color: transparent;
    span {
        color: #666;
        font-size: 11px;
        font-weight: 700;
        line-height: 1.5;
    }

    &:hover {
        span {
            color: #333;
        }
    }
}

.record-icon {
    div {
        margin: 0 0 0 10px;
    }
}
.record-slash-icon {
    margin: 0px 0 -11px 2px;
    padding: 0;
}
