.video-controller {
    position: absolute;
    z-index: 2;
    top: 0;
    padding: 3px;
    width: 100%;
    height: 100%;
    line-height: 1;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    .topbar {
        display: flex;
        justify-content: space-between;

        .timer {
            background: #ffffff50;
            font-size: 11px;
            font-weight: 700;
            padding: 2px 4px;
            border-radius: 6px;
            height: 16px;

            position: relative;
           
        }

        .expander {
            height: 18px;
            padding: 2px;
        }
    }

    .bottom-bar {

        display: flex;

        .btn-icon {
            background: #ffffff90;
            border-radius: 50%;
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            cursor: pointer;

            &.end-call {
                background: #dd0000;
            }
        }
    }
}
