.statistic-widget {
    padding: 0 0 0 13px;
    .card-body {
        display: flex;
        justify-content: space-between;
        padding: 24px;

        .container-card-contrainer-info {
            text-align: center;

            .container-card-title {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                margin-top: 5px;
                color: var(--grey);

                display: -webkit-box;
                max-width: 215px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .container-card {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 84px;
            min-height: 84px;

            // display: none;

            @media (min-width: 992px) {
                display: flex;
            }

            .container-card-image,
            img {
                // background-image: url("../../../../assets/images/incomingCalls.png");
                background-repeat: no-repeat;
                background-size: cover;
                max-height: 80px !important;
            }
        }
    }
}
