.container-header-manage-new-user {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: var(--primary-font);
    .title-user {
        margin: 0;
        padding: 0;
    }
   
}
.content-form-manage-user {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .content-form-manage-user-left {
        width: 223px;
        height: 82px;
        font-size: var(--fontSize16);
        background-color: var(--background-secondary-color);
        color: var(--primary-color);
        position: relative;
        border-radius: 10px;
        &::after {
            content: '';
            width: 3px;
            color: var(--primary-color);
            background-color: var(--primary-color);
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            font-size: var(--fontSize30);
            top: 18px;
            left: 0px;
            position: absolute;
            min-height: 48px;
        }
        .icon-user {
            font-weight: var(--font-weight-bold);
            position: absolute;
            top: 25px;
            left: 30px;
        }
        .icon-text {
            font-weight: var(--font-weight-semi-bold);
            position: absolute;
            top: 27px;
            left: 70px;
        }
    }
    .content-form-manage-user-right {
        width: calc(100% - 223px);
        height: 100%;
        min-height: auto;
        background-color: var(--background-secondary-color);
        padding: 35px 20px 15px 10px;
        margin: 0 0 0 40px;
        border-radius: 10px;
        position: relative;
        display: flex;
        justify-content: space-between;
        &::after {
            content: '';
            width: 3px;
            min-height: 97%;
            color: var(--primary-color);
            background-color: var(--primary-color);
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            font-size: var(--fontSize30);
            top: 6px;
            left: 0px;
            position: absolute;
        }
        .table-form-manage-user-label {
            width: 200px;
        }
        .table-form-manage-user-input {
            width: calc(100% - 200px);
        }
        .list-manage-new-user {
            text-decoration: none;
            list-style-type: none;
            padding: 0 0 65px 20px;
            li {
                min-height: 85px;
                padding: 10px 0 0 0;
                position: relative;
                span{
                    position: absolute;
                    top: 15px;
                    left: 0;
                }
                .error-message{
                    color: var(--danger-primary-color);
                    font-size: var(--fontSize13);
                    font-weight: var(--font-weight-semi-bold);
                    margin: 0;
                    padding: 0;
                }
            }
            .first-row {
                min-height: 130px;
                position: relative;
                & > .label {
                    position: absolute;
                    top: 27px;
                    left: 0;
                }
            }
            .image-profile {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-position: center; /* Center the image */
                background-repeat: no-repeat; /* Do not repeat the image */
                background-size: cover; /* Resize the background image to cover the entire container */
            }
          
            .icon-edit {
                width: 27px;
                height: 27px;
                background: url('./../../../public/assets/icons/icon-editing.png');
                top: 65px;
                left: 50px;
                position: absolute;
                border: 0px solid #ccc;
                display: inline-block;
                padding: 6px 12px;
                cursor: pointer;
                &::before{
                    content: '';
                    display: inline-block;
                    border: 0px;
                    border-radius: 3px;
                    padding: 15px 8px;
                    outline: none;
                    white-space: nowrap;
                    -webkit-user-select: none;
                    cursor: pointer;
                }
            }
        }
        // .footer-table-manage-new-user {
        //     width: 100%;
        //     .reset-form {
        //         font-size: var(--fontSize14);
        //         background: transparent;
        //         color: var(--fourth-color);
        //         position: relative;
        //         span {
        //             position: absolute;
        //             top: 2px;
        //             left: 20px;
        //             font-size: 16px;
        //             font-weight: var(--font-weight-semi-bold);
        //         }
        //         span + span {
        //             position: absolute;
        //             top: 1px;
        //             left: 40px;
        //             font-size: 14px;
        //             font-weight: var(--font-weight-semi-bold);
        //         }
        //     }
        // }
    }
}
