.button-group {
    display: flex;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    height: 39px;

    .item {
        text-align: center;
        padding: 4px 10px;
        // height: 39px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        label {
            margin-bottom: 0;
            font-family: var(--primary-font);
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
            cursor: pointer;
        }

        &.selected {
            background-color: var(--primary-color);
            border-radius: 10px !important;
            label {
                color: #fff;
            }
        }

        &:hover:not(.selected) {
            background-color: #e7e7e7;
            border-radius: 10px !important;
        }

        input[type="radio"] {
            display: none;
        }
    }
}
