.container-date-picker {
    width: 70%;
    font-size: var(--fontSize14);
    font-weight: var(--font-weight-regular);
    font-family: var(--primary-font);
    display: flex;
    justify-content: space-between;
    .dash{
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-semi-bold);
    }
   

    .container-date-picker-option-starttime {
        display: flex;
        justify-content: space-between;
        padding: 0 5px 0 0;
        .start-date {
            position: relative;
            .input-start-date {
                width: 180px;
                height: 38px;
                border: 1px solid #c5c5c5;
                margin: 0 0 0px 0;
                padding: 0 0 0 15px;
                border-radius: 0 10px 10px 0;
                border-left: 0px;
            }
        }
        .start-time {
            display: inline-flex;
            position: relative;
            width: 91px;
            .rc-time-picker-input{
                height: 38px;
                font-size: var(--fontSize14) !important;
                font-weight: var(--font-weight-regular) !important;
                font-family: var(--primary-font) !important;
                border-radius: 10px;
                color: black ;
            }
           
        }
    }
    .container-date-picker-option-endtime {
        display: flex;
        justify-content: space-between;
        margin: 0 0 0 5px;
        .end-date {
            position: relative;
            .input-end-date {
                width: 300px;
                height: 38px;
                border: 1px solid #c5c5c5;
                margin: 0 0 0px 10px;
                padding: 0 0 0 15px;
                border-radius: 10px;
                font-size: var(--fontSize14) !important;
                font-weight: var(--font-weight-regular) !important;
                font-family: var(--primary-font) !important;
            }
        }
        .end-time {
            display: inline-flex;
            position: relative;
            width: 91px;
            .rc-time-picker-input{
                height: 38px;
                font-size: var(--fontSize14) !important;
                font-weight: var(--font-weight-regular) !important;
                font-family: var(--primary-font) !important;
                border-radius: 10px;
                color: black;
            }
        }
    }
    .icon-calendar {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 38px;
        background-color: var(--primary-color);
        color: var(--fifth-color);
        cursor: pointer;
        text-align: center;
        border-radius: 0 10px 10px 0;
        padding: 2px 0 0 0;
    }
}

*,
::after,
::before {
    box-sizing: border-box;
}
.react-datepicker__input-container{
    font-size: var(--fontSize14) !important;
    font-weight: var(--font-weight-regular) !important;
    font-family: var(--primary-font) !important;
}

.rc-time-picker-clear{
    top: 8px !important;
}
