$fontFamilyOpenSans: var(--primary-font);


.input-email-to-email {
    box-sizing: border-box;
    position: relative;
    width: 396px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin: 0 auto;
}

.button-update-password {
    position: relative;
    width: 392px;
    height: 33px;
    background: var(--primary-color);
    border-radius: 7px;
    border: 0px;
    color: white;
    margin: 90px 0 0 0;
    // font-size: 14px;

    &:hover {
        background: var(--primary-color);
    }
    font-size: 14px;
    font-family: $fontFamilyOpenSans;
    font-weight: var(--font-weight-semi-bold);
}
.button-login:hover {
    background: #5044d3;
    font-size: 15px;
}


.title-text-top {
    color: #1e1e1e;
    font-family: $fontFamilyOpenSans ;
    font-size: var(--fontSize24) ;
    text-align: center;
   
}

.title-text-top-container {
    // width: 429px;
    padding: 0 20px;
    color: black !important;
    font-size: 24px;
    font-family: $fontFamilyOpenSans;
    font-weight: var(--font-weight-semi-bold);
}
.title-text-top-container {
    width: 429px;
    font-family: $fontFamilyOpenSans;
}
.card-container {
    width: 460px;
}
.back-to-login {
    font-size: 14px;
    font-family: $fontFamilyOpenSans;
    font-weight: var(--font-weight-bold);
    color: var(--font-secondary-color);
}


