.table-users {
    padding: 30px 0 10px 0px;

    .table-data-user {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 5px;

        thead {
            background: var(--background-secondary-color);
            tr th {
                font-family: var(--primary-font);
                font-size: var(--fontSize16);
                border: solid 0px var(--border-secondary-color);
                border-style: none solid solid none;
                padding: 5px 0 0 20px;
                height: 38px;
                position: relative;
                cursor: pointer;
                .header-user {
                    position: absolute;
                    top: 5px;
                    left: 34px;
                    font-weight: var(--font-weight-bold);
                }
            }
            th:last-child {
                border-radius: 0 10px 10px 0;
            }
            th:first-child {
                border-radius: 10px 0 0 10px;
            }
        }
        tbody {
            width: 100%;
            border-spacing: 0 10px;
            tr {
                margin: 5px !important;
                border: 0;
                border-radius: 10px;
                background: var(--background-secondary-color);
                cursor: pointer;
                &:hover {
                    background: rgb(243, 243, 243);
                }

                td {
                    border: 1px solid lightgray;
                    font-family: var(--primary-font);
                    font-size: var(--fontSize16);
                    border: solid 0px var(--border-secondary-color);
                    border-style: none solid solid none;
                    padding: 5px 0 0 20px;
                    height: 68px;
                    position: relative;
                    .user-edit,
                    .user-delete {
                        width: 28px;
                        height: 22px;
                        position: absolute;
                        top: 18px;
                        left: 28px;
                    }
                    .user-profile {
                        width: 42px;
                        height: 42px;
                        border-radius: 21px;
                        opacity: 1;
                        background-position: center; /* Center the image */
                        background-repeat: no-repeat; /* Do not repeat the image */
                        background-size: cover; /* Resize the background image to cover the entire container */
                        // background-image: url('./../../../public/assets/icons/empty-img.png');
                    }
                }
                td:last-child {
                    border-radius: 0 10px 10px 0;
                }
                td:first-child {
                    border-radius: 10px 0 0 10px;
                }
            }
        }
    }

    //-----------------------------Pagination--------------------


    .pagination {
        display: flex;
        justify-content:space-around;
        list-style: none;
        padding-top: 15px;
        float: right;
      }
      
      .page-link {
        display: block;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 5px 10px;
        margin: 0 2px;
        text-decoration: none;
      }
      
      a.page-link:hover {
        background-color: #cccccc;
      }
      
      .page-item.active .page-link {
        color: #ffffff;
        border: 1px var(--primary-color) solid;
        background-color: var(--primary-color) !important;
      }
}
