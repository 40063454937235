.call {
    .card {
        margin-bottom: 0;

        .card-body {
            display: flex;
            justify-content: space-between;
            padding-bottom: 0;
        }

        &.controller {
            padding: 0;
            border: 1px solid #ddd !important;
            width: calc(100vw - 524px);
            overflow: hidden;

            .card-header {
                background: #fafafa;
                padding: 8px;
                .visiting-url {
                    background: #fff;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    border-radius: 5px;
                    padding: 2px 10px;
                    font-size: 14px;
                    font-weight: 700;

                    span {
                        font-weight: 700;
                        color: #6e6e6e;
                    }
                }

                .actions {
                    display: flex;
                    justify-content: space-between;

                    div {
                        display: flex;
                    }
                }

                &.disabled {
                    opacity: 0.5;
                    svg {
                        color: #ccc;
                    }
                }
            }

            .card-body {
                padding: 1px;
                background: #fafafa;
                // background: radial-gradient(circle, rgb(215, 215, 223) 0%, rgb(213, 213, 223) 95%);
                .replayer-container {
                    height: calc(100vh - 280px);
                    flex: 1;
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    transition: padding-bottom 0.3s;

                    .rrweb {
                        z-index: 1;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;

                        .widget-rrweb {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            pointer-events: none;
                            opacity: 0;
                            transition: all 0.5s ease-in-out;
                            transform: translate(-50%, -50%);

                            .replayer-mouse {
                                background: no-repeat url("/assets/img/mouse-cursor.png") center / contain;
                                width: 30px;
                                height: 30px;
                                transition: all 250ms linear;
                                // transition: left 250ms linear, top 250ms linear;
                                margin: 0;
                                border: none;
                                border-radius: 0;
                                position: absolute;
                            }

                            &.show {
                                opacity: 1;
                            }
                            &.hide {
                                display: none;
                            }

                        }
                    }
                }
            }
        }

        &.video {
            // background: #fff;
            // border: 1px solid #ccc !important;
            width: 320px;
            height: 240px;
        }

        .card-footer {
            background: #fff;
            border-top: 0;
        }
    }
}
