.card-event-button {
    .container-btn-class {
        position: relative;
        padding: 0;
        margin: 0;
    }
    .button-event {
        background-color: var(--primary-color);
        color: var(--font-third-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-regular);
        border-radius: 15px;
        width: 145px;
        height: 38px;
        border: 0px;
        padding: 0 0 0 0;
        position: relative;
        //margin: 20px 0 0 0;
        &:hover {
            background-color: #392db8;
            font-size: var(--fontSize16);
            color: var(--font-third-color);
        }
    }
    .card {
        font-family: var(--primary-font);
        position: relative;
        z-index: 1;
        &.container-card-custom {
            height: 189px;
            padding: 18px 0 0 22px;
        }
    }
}
