.screen-drawing {
    .interactis_button_paint {
        background-color: rgb(255, 253, 253) !important;
        background-image: url("https://weface-assets.hqs.dev/img/paint.png") !important;
        background-position: 34% 73% !important;
        background-repeat: no-repeat !important;
        background-size: 103% !important;
        position: fixed !important;
        // bottom: 30px !important;
        // right: 25px !important;
        // height: 75px !important;
        // width: 75px !important;
        border-radius: 50px !important;
        // z-index: 10000 !important;

        &:hover {
            box-shadow: 0px 0px 10px #888888 !important;
        }
    }

    .float {
        float: left;
    }

    .optionDraw {
        background-color: white;
        border-radius: 10px;
        width: 300px;
        height: 300px;
        position: absolute;
        right: 25px;
        bottom: 130px;
        padding: 20px;
        z-index: 10000 !important;
    }

    .drawtitle {
        font-size: 25px;
        font-weight: 600;
    }

    .iconDraw {
        width: 35px;
        margin-right: 15px;
    }

    .spaceBoxDraw {
        display: flex;
        padding: 10px;
    }

    .drawingSpace {
        position: absolute;
        z-index: 1;
    }

    .drawingBorder {
        border-left: 11px solid transparent !important;
        border-right: 11px solid transparent !important;
        border-top: 28px solid #105b93 !important;
        margin-left: 40px;
        padding: 5px 10px 5px 10px;
    }

    .drawingFont {
        margin-top: -30px;
        font-size: 16px;
        font-weight: 600;
        color: white;
    }

    @media (max-width: 640px) {
        .drawingBorder {
            border-left: 8px solid transparent !important;
            border-right: 8px solid transparent !important;
            border-top: 25px solid #105b93 !important;
            margin-left: 25px;
            padding: 9px 10px 0px 10px;
        }

        .drawingFont {
            margin-top: -30px;
            font-size: 12px;
            font-weight: 600;
            color: white;
        }
    }
}

.canvas-drawing {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;

    transition: all 0.25s ease-out;

    &::after {
        content: 'Drawing Mode';
        font-size: 11px;
        color: #fff;
        background-color: #00000095;
        padding: 0 10px 2px;
        position: absolute;
        left: 45%;
        height: 26px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 10px;

    }

    &.show {
        opacity: 1;
        visibility: visible;
        background-color: #00000050;
        cursor: url("../../../assets/img/marker.png"), auto;

    }

    &.drawing {
        // cursor: pencil;
    }
}
