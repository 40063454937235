.bg-table {
    background-color: #ffffff;
    border-radius: 9px;
    padding: 30px;
    // width: calc(100% - 27px);
}
table {
    tbody {
        tr {
            td {
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
}

.pagination {
    border: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    width: fit-content;
    list-style-type: unset;
    border: 1px solid var(--border-secondary-color);
    padding: 8px 10px;
    border-radius: 15px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-family: var(--primary-font);
    font-weight: var(--font-weight-regular);
    font-size: var(--fontSize14);
    float: right;
    margin-top: 15px;

    .page-item {
        list-style: none;

        a {
            border-color: var(--primary-color);
            color: var(--primary-color);
            border-radius: 4px;
        }
        &.active {
            :hover {
                color: #fff !important;
            }
            a {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
            }
        }
    }

    .pagination-item {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }

    .pagination-item:hover {
        cursor: pointer;
        background-color: #e1e4e7;
        -webkit-transition: background-color 0.1s linear;
        -moz-transition: background-color 0.1s linear;
        -o-transition: background-color 0.1s linear;
        transition: background-color 0.1s linear;
    }

    .pagination-item-active {
        color: white;
        background-color: var(--primary-color);
        pointer-events: none;
        -webkit-transition: background-color 0.1s linear;
        -moz-transition: background-color 0.1s linear;
        -o-transition: background-color 0.1s linear;
        transition: background-color 0.1s linear;
    }
    .pagination-item-active:hover {
        background-color: var(--background-primary-color);
    }
}
