.card-time {
    &.card {
        font-family: var(--primary-font);
        position: relative;
        z-index: 1;
        &.container-card-custom {
            height: 189px;
        }
    }

    .card-body {
        position: relative;
        .input-group {
            display: flex;
            align-items: center;
            margin-top: 50px;

            position: absolute;
            bottom: 30px;
        }
    }

    .title {
        color: var(--font-fourth-color);
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-bold);
    }
    .focus-text {
        color: var(--primary-color);
        font-size: var(--fontSize16);
        font-weight: var(--font-weight-bold);
    }
    .input-time {
        border: 0px;
        width: 107px;
        color: var(--font-secondary-color);
        font-size: var(--fontSize14);
        height: 27px;
    }
    .lable-unit {
        color: var(--font-primary-color);
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-semi-bold);
        margin: 0 0 0 20px;
    }
}
