.container-preview{
    top: 14px;
    right: 13px;
    /* UI Properties */
    background: #6259CE1A 0% 0% no-repeat padding-box;
    border: 1px solid #B7B6B6;
    opacity: 1;
    position: absolute;
    border-radius: 15px;
    .area-preview{
        width: 300px;
        height: 160px;
    }
}