.table-current-visitor {
    .table {
        margin-bottom: 0;
        thead {
            tr th {
                border-bottom: 0;
                padding: 2px 15px;
                font-size: 14px;
            }
        }

        tbody {
            tr td {
                background: #fafafa;
                font-size: 14px;
                padding: 5px 15px !important;
                vertical-align: middle;

                &:first-of-type {
                    border-radius: 10px 0 0 10px;
                }

                &:last-of-type {
                    border-radius: 0 10px 10px 0;
                }

            }
        }
    }
}
