.chat {
    overflow: hidden;
    position: fixed !important;
    width: 320px;
    top: 313px;
    right: 45px;
    z-index: 1000 !important;

    .card-header {
        padding: 15px 20px;
        // background-color: #fafafa;
        border-bottom: 0;
        h4 {
            font-size: 12px;
            margin-bottom: 0;
            padding-bottom: 5px;
            color: #666;

            display: flex;
            border-bottom: 1px solid #ddd;

            svg {
                margin-right: 5px;
            }
        }
    }

    .card-body {
        // background-color: #fafafa;
        padding: 5px 20px;

        height: calc(100vh - 540px);
        overflow: hidden;

        .message-container {
            border-bottom: 1px solid #ddd;

            width: 100%;
            height: 100%;

            overflow: hidden;

            padding-right: 5px; /* Increase/decrease this value for cross-browser compatibility */
            box-sizing: content-box; /* So the width will be 100% + 17px */
            &:hover {
                overflow-y: scroll;
                .message-box {
                    .message-wrapper {
                        .message {
                            margin-right: -4px;
                        }
                    }
                }
            }

            .message-box {
                display: table-cell;
                vertical-align: bottom;
                height: calc(100vh - 490px);
                width: 320px;

                .message-wrapper {
                    display: flex;
                    flex-direction: row;

                    .message {
                        display: flex;
                        flex-direction: column;

                        align-items: self-start;

                        position: relative;
                        margin-bottom: 5px;

                        .avatar {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #d7d5ec;

                            position: absolute;
                            top: 2px;
                        }

                        .text {
                            max-width: 160px;
                            background: #fff;
                            padding: 6px 10px;
                            border-radius: 8px;
                            margin-left: 30px;
                            margin-right: 28px;
                            margin-bottom: 5px;
                            color: #666 !important;
                            font-size: 10px;
                            line-height: 1.5;
                            font-weight: 600;
                            box-shadow: 0 5px 15px #33333315;
                            min-height: 14px;
                        }

                        .file {
                            max-width: 160px;
                            background: #fff;
                            padding: 6px 10px;
                            border-radius: 8px;
                            margin-left: 30px;
                            margin-right: 28px;
                            margin-bottom: 5px;
                            color: #666 !important;
                            font-size: 10px;
                            line-height: 1.5;
                            box-shadow: 0 5px 15px #33333315;
                            min-height: 24px;

                            padding-left: 30px;

                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            span {
                                display: block;
                                font-size: 8.5px;
                                margin-top: 5px;
                                color: #aaa;
                            }

                            svg {
                                position: absolute;
                                left: 35px;
                                top: 8px;
                            }
                        }
                    }

                    &.agent {
                        flex-direction: row-reverse;
                        .message {
                            align-items: self-end;
                            .avatar {
                                margin-right: -2px;
                            }

                            .text {
                                background: #e4e8f0;
                                color: var(--primary-color) !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .card-footer {
        border-top: 0;
        padding-top: 0;

        form {
            width: 100%;

            .form-footer {
                display: flex;
                justify-content: space-between;
                padding-top: 5px;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 36px;
                    height: 32px;

                    border-radius: 6;
                    padding: 0;

                }

                .btn-upload {
                    background-color: #eee;
                }

                .btn-submit {
                    background-color: var(--primary-color);
                }
    
                .btn-chat-gpt {
                    background-color: var(--primary-color);
                    margin-right: 5px;
                }
            }
           
        }

        .input-group {
            display: flex;
            align-items: center;
            .form-control {
                background-color: transparent;
                border: 0;
                font-size: 12px;
                height: 90px;
                padding: 10px 10px 10px 4px;
                resize: none;
                line-height: 1.5;
            }
            button {
                width: 50px;
                height: 32px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
            }
        }
    }
}
