.button-toggle {
    .custom-control-label {
        max-width: 36px;
    cursor: pointer;

        &::after {
            content: "";
            border-radius: 50% !important;
        }
        &::before {
            content: "";
            border-radius: 20px !important;
        }
    }
}

.custom-switch.custom-switch-text .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1rem);
}
