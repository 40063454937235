.containter-dialog-remove {
    width: 604px;
    height: 242px;
    border-radius: 15px;
    background: #fefefe 0% 0% no-repeat padding-box;
    .containter-dialog-remove-box {
        padding: 28px;
        display: flex;
        justify-content: space-between;
        .dialog-remove-bin {
            width: 100px;
            height: 100px;
            padding: 0 32px 0 0;
        }
        .dialog-remove-content {
            width: calc(100% - 100px);
            height: 100px;
            margin: 0 0 0 32px;
            position: relative;
            .dialog-remove-content-title {
                color: var(--grey);
                text-align: left;
                font-size: var(--fontSize18);
                font-weight: var(--font-weight-semi-bold);
                letter-spacing: 0px;
                position: absolute;
                top: 22px;
                left: 0;
            }
            .dialog-remove-content-subtitle {
                width: 430px;
                text-align: left;
                font-size: var(--fontSize14);
                font-weight: var(--font-weight-regular);
                letter-spacing: 0px;
                color: var(--font-fourth-color);
                position: absolute;
                top: 50px;
                left: 0;
            }
        }
    }
    .containter-dialog-remove-footer {
        display: flex;
        justify-content: space-between;
        position: relative;
        .dialog-remove-cancel-btn {
            width: 160px;
            height: 19px;
            position: absolute;
            top: 21px;
            left: 40px;
            text-align: left;
            letter-spacing: 0px;
            color: var(--font-fourth-color);
            text-transform: capitalize;
            font-size: var(--fontSize14);
            font-weight: var(--font-weight-regular);
            cursor: pointer;
            span + span {
                margin: 0 0 0 10px;
            }
        }
        .dialog-remove-del-btn {
            width: 160px;
            height: 38px;
            position: absolute;
            top: 18px;
            right: 28px;
            border-radius: 10px;
            background-color: var(--danger-primary-color);
            font-size: var(--fontSize14);
            font-weight: var(--font-weight-regular);
            color: var(--fifth-color);
            padding: 4px 0 0 15px;
            cursor: pointer;
            .icon {
                position: absolute;
                top: 1px;
                left: 15px;
            }
            .text {
                position: absolute;
                top: 3px;
                left: 40px;
            }
        }
    }
}

@media screen and (max-width: 700px){
    .modal-content {
        width: calc(100vw)
    }
    .containter-dialog-remove{
        width: calc(100vw - 100px)
    }
    .dialog-remove-content-subtitle{
        width: fit-content !important;
    }
}

@media screen and (max-width: 600px){
    .modal-content {
        width: calc(100vw)
    }
    .containter-dialog-remove{
        width: calc(100vw - 20px)
    }
    .dialog-remove-content-subtitle{
        width: fit-content !important;
    }
}
