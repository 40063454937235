.trigger-detail-app {
    .quill {
        min-width: unset !important;
        max-width: unset !important;
    }
    .card {
        .card-header {
            border-bottom: 0;
            h3 {
                font-size: 20px;
            }
        }

        .card-body {
            .contianer-trigger-date-option {
                width: 100%;
                // margin: 0 -80px 0 -100px;
            }
            form {
                .form-control {
                    max-width: 560px;
                }
                .trigger-designer {
                    position: relative;
                    height: 200px;

                    .base-colors {
                        display: flex;
                        justify-content: space-evenly;
                        width: 160px;
                        position: absolute;
                        // top: 10px;
                        // right: 10px;
                        left: 510px;
                        bottom: 260px;

                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            width: 20px;
                            height: 20px;
                            border-radius: 50%;

                            cursor: pointer;

                            &:last-of-type {
                                margin-left: 5px;
                                button {
                                }
                            }
                        }
                    }
                    .trigger-preview-design {
                        width: 100%;
                        right: 0;
                        top: 0;
                        position: relative;
                    }
                }
            }
        }
    }
    .form-group .quill {
        width: 75%;
        margin: 0 0 0 0;
    }
    .card-position {
        height: 685px;
        .card-body {
            .row {
                div[class^='col-'] {
                    padding: 0;
                    display: flex;
                    justify-content: flex-start;

                    &:first-of-type {
                        justify-content: flex-end;
                    }
                }
            }
            .position-select {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 230px;
                .position-wrapper {
                    background-color: #eeedf9;
                    border: 1px solid #cac9ce;
                    border-radius: 10px;
                    width: 100%;
                    height: 120px;
                    cursor: pointer;

                    transition: all 0.1s linear;
                }

                label {
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                }

                &.active {
                    .position-wrapper {
                        border: 2px solid var(--primary-color);
                    }
                }
            }
        }
    }

    .trigger-time {
        display: inline-flex;
        position: relative;
        height: 38px;
        font-size: var(--fontSize14) !important;
        font-weight: var(--font-weight-regular) !important;
        font-family: var(--primary-font) !important;
        border-radius: 10px;
        color: black;
        // border: 1px solid lightgray;
        text-align: center;
        .rc-time-picker-input {
            border-radius: 10px;
            height:100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .trigger-time {
            top: 6px;
            right: 10px;
        }
        .card-position {
            height: 732px;
        }
        .card {
            .card-body {
                .contianer-trigger-date-option {
                    width: 100%;
                    margin: 0 -80px 0 0;
                    .container-date-picker-fully {
                        .first-section {
                            margin: 0 0 10px 0;
                        }
                    }
                }
                form {
                    .form-control {
                        max-width: 560px;
                    }
                    .trigger-designer {
                        position: relative;
                        height: 200px;
                        right: 440px;
                        top: 50px;
                        .trigger-preview-design {
                            left: 120px;
                            top: 0 !important;
                            .trigger-toggle-slide-preview-component {
                                margin-left: 327px !important;
                            }
                        }

                        .base-colors {
                            display: flex;
                            justify-content: space-evenly;
                            width: 160px;
                            position: absolute;
                            // top: 10px;
                            // right: 10px;
                            left: 547px;
                            bottom: 257px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                width: 20px;
                                height: 20px;
                                border-radius: 50%;

                                cursor: pointer;

                                &:last-of-type {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-group .quill {
            width: 75%;
            margin: 0 0 0 -8px;
        }
        .form-horizontal .form-group .form-control {
            margin: 0 0 0 23px;
        }
        .container-date-picker-fully {
            display: block;
        }

        .container-date-picker-fully {
            .start-date {
                right: 52px;
                .icon-calendar {
                    margin: 0 0 0px 0;
                }
            }

            .container-date-picker-fully-option-endtime {
                margin: 10px 35px 0 0;
                .end-date {
                    right: 23px;
                    .input-end-date {
                        margin: 0 0 0 0;
                    }
                    .icon-calendar {
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1280px) {
        .trigger-time {
            top: 6px;
            right: 10px;
        }
        .card-position {
            height: 732px;
        }
        .card {
            .card-body {
                .contianer-trigger-date-option {
                    width: 100%;
                    margin: 0 -80px 0 0;
                    .container-date-picker-fully {
                        .first-section {
                            margin: 0 0 10px 0;
                        }
                    }
                }

                form {
                    .form-control {
                        max-width: 560px;
                    }
                    .trigger-designer {
                        position: relative;
                        height: 200px;
                        right: 400px;
                        .trigger-preview-design {
                            right: -40px !important;
                            top: 0 !important;
                            .trigger-toggle-slide-preview-component {
                                margin-left: 327px !important;
                            }
                        }

                        .base-colors {
                            display: flex;
                            justify-content: space-evenly;
                            width: 160px;
                            position: absolute;
                            // top: 10px;
                            // right: 10px;
                            left: 507px;
                            bottom: 251px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                width: 20px;
                                height: 20px;
                                border-radius: 50%;

                                cursor: pointer;

                                &:last-of-type {
                                    margin-left: 5px;
                                    button {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-group .quill {
            width: 78%;
            margin: 0 0 0 -8px;
        }
        .form-horizontal .form-group .form-control {
            margin: 0 0 0 23px;
        }
        .container-date-picker-fully {
            display: block;
        }

        .container-date-picker-fully {
            .start-date {
                right: 52px;
                .icon-calendar {
                    margin: 0 0 0px 0;
                }
            }

            .container-date-picker-fully-option-endtime {
                margin: 10px 35px 0 0;
                .end-date {
                    right: 23px;
                    .input-end-date {
                        margin: 0 0 0 0;
                    }
                    .icon-calendar {
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .trigger-time {
            top: 6px;
            right: 10px;
        }
        .card-position {
            height: 732px;
        }
        .card {
            .card-body {
                .contianer-trigger-date-option {
                    width: 100%;
                    margin: 0 -80px 0 0;
                    .container-date-picker-fully {
                        .first-section {
                            margin: 0 0 10px 0;
                        }
                    }
                }

                form {
                    .form-control {
                        max-width: 560px;
                    }
                    .trigger-designer {
                        position: relative;
                        right: 400px;
                        top: 0;
                        .trigger-preview-design {
                            left: 190px !important;
                            top: 17px !important;
                            .trigger-toggle-slide-preview-component {
                                margin-left: 327px !important;
                            }
                        }

                        .base-colors {
                            display: flex;
                            justify-content: space-evenly;
                            width: 160px;
                            position: absolute;
                            // top: 10px;
                            // right: 10px;
                            left: 740px;
                            bottom: 260px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                width: 20px;
                                height: 20px;
                                border-radius: 50%;

                                cursor: pointer;

                                &:last-of-type {
                                    margin-left: 5px;
                                    button {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-group .quill {
            width: 75%;
            margin: 0 0 0 -8px;
        }
        .form-horizontal .form-group .form-control {
            margin: 0 0 0 23px;
        }
        .container-date-picker-fully {
            display: block;
        }

        .container-date-picker-fully {
            .start-date {
                right: 52px;
                .icon-calendar {
                    margin: 0 0 0px 0;
                }
            }

            .container-date-picker-fully-option-endtime {
                margin: 10px 35px 0 0;
                .end-date {
                    right: 23px;
                    .input-end-date {
                        margin: 0 0 0 0;
                    }
                    .icon-calendar {
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1440px) {
        .trigger-time {
            top: 6px;
            right: 10px;
        }
        .card-position {
            height: 732px;
        }
        .card {
            .card-body {
                .contianer-trigger-date-option {
                    width: 100%;
                    margin: 0 -80px 0 0;
                    .container-date-picker-fully {
                        .first-section {
                            margin: 0 0 10px 0;
                        }
                    }
                }
                form {
                    .form-control {
                        max-width: 560px;
                    }
                    .trigger-designer {
                        position: relative;
                        height: 200px;
                        right: 400px;
                        top: 0;
                        .trigger-preview-design {
                            left: 120px;
                            top: 0 !important;
                            .trigger-toggle-slide-preview-component {
                                margin-left: 327px !important;
                            }
                        }

                        .base-colors {
                            display: flex;
                            justify-content: space-evenly;
                            width: 160px;
                            position: absolute;
                            // top: 10px;
                            // right: 10px;
                            left: 777px;
                            bottom: 257px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                width: 20px;
                                height: 20px;
                                border-radius: 50%;

                                cursor: pointer;

                                &:last-of-type {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-group .quill {
            width: 80%;
            margin: 0 0 0 -8px;
        }
        .form-horizontal .form-group .form-control {
            margin: 0 0 0 23px;
        }
        .container-date-picker-fully {
            display: block;
        }

        .container-date-picker-fully {
            .start-date {
                right: 52px;
                .icon-calendar {
                    margin: 0 0 0px 0;
                }
            }

            .container-date-picker-fully-option-endtime {
                margin: 10px 35px 0 0;
                .end-date {
                    right: 23px;
                    .input-end-date {
                        margin: 0 0 0 0;
                    }
                    .icon-calendar {
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1680px) {
        .trigger-time {
            top: 6px;
            right: 10px;
        }
        .card-position {
            height: 732px;
        }
        .card {
            .card-body {
                .contianer-trigger-date-option {
                    width: 100%;
                    margin: 0 -80px 0 0;
                    .container-date-picker-fully {
                        .first-section {
                            margin: 0 0 10px 0;
                        }
                    }
                }
                form {
                    .form-control {
                        max-width: 592px;
                        margin: 0 0 0 -8px;
                    }
                    .trigger-designer {
                        position: relative;
                        height: 200px;
                        right: 400px;
                        top: 0;
                        .trigger-preview-design {
                            left: 120px;
                            top: 0 !important;
                            .trigger-toggle-slide-preview-component {
                                margin-left: 327px !important;
                            }
                        }

                        .base-colors {
                            display: flex;
                            justify-content: space-evenly;
                            width: 160px;
                            position: absolute;
                            // top: 10px;
                            // right: 10px;
                            left: 933px;
                            bottom: 257px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                width: 20px;
                                height: 20px;
                                border-radius: 50%;

                                cursor: pointer;

                                &:last-of-type {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-group .quill {
            width: 83%;
            margin: 0 0 0 -8px;
        }
        .form-horizontal .form-group .form-control {
            margin: 0 0 0 23px;
        }
        .container-date-picker-fully {
            display: block;
        }

        .container-date-picker-fully {
            .start-date {
                right: 52px;
                .icon-calendar {
                    margin: 0 0 0px 0;
                }
            }

            .container-date-picker-fully-option-endtime {
                margin: 10px 35px 0 0;
                .end-date {
                    right: 23px;
                    .input-end-date {
                        margin: 0 0 0 0;
                    }
                    .icon-calendar {
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1920px) {
        .trigger-time {
            top: 6px;
            right: 10px;
        }
        .card-position {
            height: 732px;
        }
        .card {
            .card-body {
                .contianer-trigger-date-option {
                    width: 100%;
                    margin: 0 -80px 0 0;
                    .container-date-picker-fully {
                        .first-section {
                            margin: 0 0 10px 0;
                        }
                    }
                }
                form {
                    .form-control {
                        max-width: 560px;
                    }
                    .trigger-designer {
                        position: relative;
                        height: 200px;
                        right: 400px;
                        top: 0;
                        .trigger-preview-design {
                            left: 118px;
                            top: 0 !important;
                            .trigger-toggle-slide-preview-component {
                                margin-left: 327px !important;
                            }
                        }

                        .base-colors {
                            display: flex;
                            justify-content: space-evenly;
                            width: 160px;
                            position: absolute;
                            // top: 10px;
                            // right: 10px;
                            left: 907px;
                            bottom: 257px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                width: 20px;
                                height: 20px;
                                border-radius: 50%;

                                cursor: pointer;

                                &:last-of-type {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-group .quill {
            width: 67%;
            margin: 0 0 0 -8px;
        }
        .form-horizontal .form-group .form-control {
            margin: 0 0 0 -8px;
        }
        .container-date-picker-fully {
            display: block;
        }

        .container-date-picker-fully {
            .start-date {
                right: 52px;
                .icon-calendar {
                    margin: 0 0 0px 0;
                }
            }

            .container-date-picker-fully-option-endtime {
                margin: 10px 35px 0 0;
                .end-date {
                    right: 23px;
                    .input-end-date {
                        margin: 0 0 0 0;
                    }
                    .icon-calendar {
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }
}
