.current-visitor {
    .toolbar {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .badge {
            height: 30px;
            &.badge-calling {
                span {
                    font-weight: 700;
                }
                color: var(--success-primary-color);
                background-color: var(--success-secondary-color);
            }

            &.badge-danger {
                opacity: 1;
                background-color: transparent;
                span {
                    color: red;
                }
                &.auto-hide {
                    -moz-animation: cssAnimation 0.25s ease-in 5s forwards;
                    /* Firefox */
                    -webkit-animation: cssAnimation 0.25s ease-in 5s forwards;
                    /* Safari and Chrome */
                    -o-animation: cssAnimation 0.25s ease-in 5s forwards;
                    /* Opera */
                    animation: cssAnimation 0.25s ease-in 5s forwards;
                    -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards;
                }

                @keyframes cssAnimation {
                    to {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                @-webkit-keyframes cssAnimation {
                    to {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }

    table.current-visitor {
        width: 100%;

        border-collapse: separate;
        border-spacing: 0 10px;

        margin-bottom: 0;

        // .button-container-bg-vdo {
        //     background-color: var(--secondary-color);
        // }

        // .button-container-bg-sms {
        //     background-color: var(--success-secondary-color);
        // }

        thead {
            tr {
                th {
                    white-space: nowrap;
                    padding: 10px 10px;

                    &:first-child {
                        border-radius: 10px 0 0 10px;
                    }
                    &:last-child {
                        border-radius: 0 10px 10px 0;
                    }
                }
            }
        }

        tbody {
            margin-top: 10px;
            tr {
                // cursor: pointer;

                &.highlight {
                    position: relative;
                    // cursor: pointer;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 1px;
                        right: 1px;
                        width: calc(100% - 2px);
                        height: 99%;
                        border: 1px solid red;
                        border-radius: 10px;
                        z-index: 0;
                    }
                }

                &.no-highlight {
                    position: relative;
                    cursor: pointer;
                }

                &.spacer {
                    td {
                        background-color: white;
                        padding: 5px !important;
                    }
                }

                td {
                    border-top: 0;
                    border-bottom: 0 !important;
                    white-space: nowrap;
                    font-size: 14px;
                    padding: 15px 10px;
                    color: var(--grey);

                    &:first-child {
                        border-radius: 10px 0 0 10px;
                    }
                    &:last-child {
                        border-radius: 0 10px 10px 0;
                    }

                    .badge-icon {
                        height: 15px;
                        width: 15px;
                        background-color: var(--danger-primary-color);
                        border-radius: 50%;
                        display: inline-block;
                        border: 2px solid var(--border-primary-color);
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    a {
                        z-index: 1;
                        position: relative;
                    }
                }
            }
        }

        .online-icon {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #28c66f;
            box-shadow: 0 2px 5px #a39f9f;
        }

        .online-container {
            width: 75px;
            height: 40px;
            border-radius: 9px;
            margin: 2px 0 0;
            padding: 5px 0 0 18px;
        }

        .line-vertical {
            // background-image: url("../../../assets/images/line-vertical.png");
            width: 2px;
            height: 40px;
            background: repeat-y;
            opacity: 0.5;
        }

        .ip-address-text {
            margin: 0 0 0 0;
            position: relative;
        }

        .tr-width {
            width: 260px;
        }

        .table .ligth th,
        td {
            background: var(--background-primary-color);
        }

        .ligth th {
            background: var(--background-primary-color);
            font-weight: var(--font-weight-bold) !important;
            font-size: var(--fontSize16) !important;
        }

        .danger-text {
            color: var(--danger-primary-color) !important;
            font-size: var(--fontSize16) !important;
        }

        .info-text {
            color: var(--info-primary-color) !important;
            font-size: var(--fontSize16) !important;
        }

        .success-text {
            color: var(--success-primary-color) !important;
            font-size: var(--fontSize16) !important;
        }

        .default-text {
            color: var(--fourth-color) !important;
            font-size: var(--fontSize16) !important;
        }

        .button-container {
            display: inline-block;
            border-radius: 10px;
            width: 64px;
            height: 44px;
            padding: 10px 14px;
            position: relative;
            z-index: 1;

            .calling {
                position: relative;
                span {
                    position: absolute;
                    top: 0;
                    left: 2;
                }
            }
        }
    }
}

.contact-visitor-video-container {
    position: fixed;
    width: 280px;
    height: 340px;
    bottom: -200px;
    opacity: 0;
    visibility: hidden;

    right: 30px;
    border-radius: 30px;
    border: 3px solid #000;
    overflow: hidden;
    z-index: 99998;
    box-shadow: rgba(0, 0, 0, 0.46) 0px 2px 15px;
    transition: all 0.25s ease-in-out;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0) 75%,
            rgba(255, 255, 255, 0.8) 100%
        );
    }

    &.show {
        bottom: 30px;
        opacity: 1;
        visibility: visible;
    }

    video {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .button-group {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        border: 0;

        button {
            z-index: 99999;
            background-color: #c70000aa;
            border: 1px solid #ffffff;
            border-radius: 30px;
            width: 50%;
            height: 44px;
            padding: 0;

            color: #fff;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            box-shadow: rgba(0, 0, 0, 0.15) 3px 0px 3px;

            &:nth-of-type(1) {
                margin-right: 5px;
                background-color: rgba(106, 192, 80, 0.667);
            }

            span {
                margin-left: 10px;
                display: flex;
                align-items: baseline;
                span {
                    margin-left: 5px;
                }
            }
        }
    }
}

.chat-request-dialog {
    opacity: 0;
    visibility: hidden;

    position: fixed;
    bottom: 15px;
    right: 30px;

    width: 300px;
    height: 300px;

    background-color: #fff;
    border-radius: 15px;

    z-index: 9998;
    box-shadow: 0 2px 10px #00000035;

    overflow: hidden;
    bottom: -100px;

    transition: all 0.25s linear;

    &.has-messages {
        height: 570px;
    }

    .card-header {
        background-color: var(--primary-color);
        padding: 12px 15px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        h4 {
            font-size: 16px;
            color: #fff;
            margin-bottom: 0;
        }
        button {
            background-color: transparent;
            height: 20px;
            display: flex;
            align-items: center;
            margin-right: -10px;
        }
    }

    .card-body {
        padding: 0 10px;

        .message-container {
            background-color: #f4f5f6;
            border-radius: 5px;

            width: 100%;

            overflow: hidden;
            visibility: hidden;

            // padding-right: 5px; /* Increase/decrease this value for cross-browser compatibility */

            box-sizing: content-box; /* So the width will be 100% + 17px */

            &:hover {
                overflow-y: scroll;
                .message-box {
                    .message-wrapper {
                        .message {
                            // margin-right: -4px;
                        }
                    }
                }
            }

            .message-box {
                display: table-cell;
                vertical-align: bottom;
                width: 275px;
                transition: all 0.25s linear;
                padding-left: 5px;
                padding-right: 2px;

                .message-wrapper {
                    display: flex;
                    flex-direction: row;

                    .message {
                        display: flex;
                        flex-direction: column;

                        align-items: self-start;

                        position: relative;
                        margin-bottom: 5px;

                        overflow-wrap: break-word;

                        .avatar {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #d7d5ec;

                            position: absolute;
                            top: 2px;
                        }

                        .text {
                            max-width: 160px;
                            background: #fff;
                            padding: 6px 10px;
                            border-radius: 8px;
                            margin-left: 30px;
                            margin-right: 28px;
                            margin-bottom: 5px;
                            color: #666 !important;
                            font-size: 10px;
                            line-height: 1.5;
                            font-weight: 600;
                            box-shadow: 0 5px 15px #33333315;
                            min-height: 14px;
                            p {
                                margin-bottom: 0;
                            }
                        }

                        .file {
                            max-width: 160px;
                            background: #fff;
                            padding: 6px 10px;
                            border-radius: 8px;
                            margin-left: 30px;
                            margin-right: 28px;
                            margin-bottom: 5px;
                            color: #666 !important;
                            font-size: 10px;
                            line-height: 1.5;
                            box-shadow: 0 5px 15px #33333315;
                            min-height: 24px;

                            padding-left: 30px;

                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            span {
                                display: block;
                                font-size: 8.5px;
                                margin-top: 5px;
                                color: #aaa;
                            }

                            svg {
                                position: absolute;
                                left: 35px;
                                top: 8px;
                            }
                        }
                    }

                    &.agent {
                        flex-direction: row-reverse;
                        .message {
                            align-items: self-end;
                            .avatar {
                                margin-right: -2px;
                            }

                            .text {
                                background: #e4e8f0;
                                color: var(--primary-color) !important;
                            }
                        }
                    }
                }
            }

            &.show {
                visibility: visible;
                margin-bottom: 10px;
                padding-bottom: 10px;
                height: 250px;

                .message-box {
                    height: 250px;
                }
            }
        }

        .form-group {
            margin-bottom: 0;
            label {
                font-size: 11px;
                margin-left: 3px;
                color: #aaa;
                line-height: 1.5;
                margin-top: 5px;
                span {
                    display: block;
                    line-height: 1;
                    margin-bottom: 5px;
                    background-color: var(--secondary-color);
                    color: var(--primary-color);
                    padding: 6px 8px;
                    border-radius: 5px;
                    font-weight: 600;
                }
            }
            .rte {
                margin-bottom: 10px;
                .ql-toolbar {
                    border-radius: 5px 5px 0 0;
                }
                .ql-container {
                    border-radius: 0 0 5px 5px;
                    height: 96px;
                    .ql-editor {
                        &::before {
                            color: #bbb;
                            font-style: normal;
                        }
                    }
                }
            }
            textarea {
                resize: none;
                border: 1px solid #ccc;
                border-radius: 5px;
                width: 100%;
                height: 100px;
                font-size: 12px;
                padding: 8px 10px;
                line-height: 20px;
                &::placeholder {
                    color: #bbb;
                }
            }
        }
    }

    .card-footer {
        border-top: 0;
        background-color: transparent;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        > div {
            display: flex;
            .btn-video-call {
                background: var(--secondary-color);
                height: 30px;
                width: 30px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .btn-chat-gpt {
                background: var(--secondary-color);
                height: 30px;
                width: 30px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .btn-primary {
            background: var(--primary-color);
            color: #fff;
            border-radius: 6px;
            width: 120px;
            font-size: 14px;
            height: 30px;
            display: flex;
            span {
                margin-left: 5px;
            }
        }
    }

    &.show {
        opacity: 1;
        visibility: visible;
        bottom: 30px;
    }
}
