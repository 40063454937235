.table-insight {

    .table-data-insight {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 5px;

        thead {
            background: var(--background-secondary-color);
            tr th {
                font-family: var(--primary-font);
                
                border: solid 0px var(--border-secondary-color);
                border-style: none solid solid none;
                padding: 5px 15px;
                height: 38px;
                position: relative;
                cursor: pointer;
                .header-insight {
                    position: absolute;
                    top: 5px;
                    left: 34px;
                    font-weight: var(--font-weight-bold);
                }
            }
            th:last-child {
                border-radius: 0 10px 10px 0;
            }
            th:first-child {
                border-radius: 10px 0 0 10px;
            }
        }
        tbody {
            width: 100%;
            border-spacing: 0 10px;
            tr {
                margin: 5px !important;
                border: 0;
                border-radius: 10px;
                background: var(--background-secondary-color);
                
                td {
                    border: 1px solid lightgray;
                    font-family: var(--primary-font);
                    font-weight: 600;
                    border: solid 0px var(--border-secondary-color);
                    border-style: none solid solid none;
                    padding: 5px 15px;
                    height: 68px;
                    position: relative;
                    color: #666;
                    .insight-delete {
                        width: 28px;
                        height: 22px;
                        position: absolute;
                        top: 18px;
                        left: 38px;
                    }
                    &.badge-center-custom {
                        padding: 0 0 0 45px;
                    }
                    .details {
                        margin: 0 0 0 18px;
                    }
                    .btn {
                        &.btn-icon {
                            width: unset;
                            padding: 0;
                            img {
                                margin-left: 0;
                            }
                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                }
                td:last-child {
                    border-radius: 0 10px 10px 0;
                }
                td:first-child {
                    border-radius: 10px 0 0 10px;
                }
            }
        }
    }
 
}
