.booked-service-calls-page {
   
    .booked-service-calls {
        // min-height: 630px;
        .date-text-card {
            color: var(--fourth-color);
            font-size: var(--fontSize12);
            font-weight: var(--font-weight-semi-bold);
            margin: 0 0 0 10px;
            color: #ffab00;
        }

        .time-text-card {
            color: var(--fourth-color);
            font-size: var(--fontSize12);
            font-weight: var(--font-weight-semi-bold);
            margin: 0 0 0 10px;
            color: #28c66f;
        }
        .minute-text-card {
            color: var(--fourth-color);
            font-size: var(--fontSize12);
            font-weight: var(--font-weight-semi-bold);
            margin: 0 0 0 10px;
            color: #6259ce;
        }
        .caption-text-card {
            font-size: var(--fontSize12);
            font-weight: var(--font-weight-semi-bold);
            color: var(--fourth-color);
            text-align: initial;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .caption-clip-card {
            text-align: end;
            margin: 0 0 0 -15px;
            font-size: var(--fontSize12);
            font-weight: var(--font-weight-bold);
            span {
                font-size: var(--fontSize12);
            }
        }
        .title-booked-service {
            font-size: var(--fontSize18);
            font-weight: var(--font-weight-semi-bold);
            color: var(--fourth-color);
            text-align: initial;
        }
        .bg-service-card {
            background-color: var(--background-primary-color);
            min-height: 180px;
        }
        .read-more {
            color: var(--fourth-color);
            font-size: var(--fontSize12);
            font-weight: var(--font-weight-semi-bold);
            position: absolute;
            bottom: 5px;
            left: 0;
        }

        .view-all-container {
            width: 100%;
            margin: 0 auto;
        }
        .view-all {
            color: var(--fourth-color);
            font-size: var(--fontSize14);
            &:link {
                text-decoration: none;
            }
            &:hover {
                text-decoration: none;
                color: var(--primary-color);
            }
            &::after {
                font-size: 12px;
                content: ' V';
            }
        }
    }
}

.modal-content{
    width: 550px;
}

.close-button-booked-service-calls {
    background-color: var(--background-secondary-color);
    color: var(--font-secondary-color);
    font-size: var(--fontSize14);
    border: 1px solid var(--border-secondary-color);
    position: relative;
    width: 105px;
    height: 30px;
    span {
        position: absolute;
        top: 3px;
        left: 35px;
    }
}

.title-booked-service-calls {
    font-size: var(--fontSize18);
    font-weight: var(--font-weight-bold);
    margin: 0 0 0 18px;
}
.label-booked-service-calls {
    font-size: var(--fontSize14);
    font-weight: var(--font-weight-semi-bold);

    &.content-booked-service-calls {
        font-size: var(--fontSize14);
        font-weight: var(--font-weight-regular);
    }
}

.description-box {
    min-width: 200px;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    max-height: 150px;
}
