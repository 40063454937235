.preview-item-left {
    width: 45px;
    position: relative;
    margin: 95px 0 0 10px;
    .avatar {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #eee;
        position: absolute;
        top: -4px;
        left: -4px;
        border: 1px solid var(--primary-color);
    }
    .message-box {
        background-color: var(--primary-color);
        border-radius: 30px;
        height: 12px;

        p {
            font-size: 10px;
            color: #fff;
            line-height: 1.4;
            width: 140px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow-wrap: break-word;
        }
    }
}

.preview-item-right {
    width: 45px;
    position: relative;
    margin: 95px 10px 0 0;
    float: right;
    .avatar {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #eee;
        position: absolute;
        top: -3px;
        left: -4px;
        border: 1px solid var(--primary-color);
    }
    .message-box {
        background-color: var(--primary-color);
        border-radius: 30px;
        height: 12px;

        p {
            font-size: 10px;
            color: #fff;
            line-height: 1.4;
            width: 140px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow-wrap: break-word;
        }
    }
}

.preview-item-left-up {
    width: 45px;
    position: relative;
    margin: 85px 0 0 10px;
    .avatar {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #eee;
        position: absolute;
        top: -4px;
        left: -4px;
        border: 1px solid var(--primary-color);
    }
    .message-box {
        background-color: var(--primary-color);
        border-radius: 30px;
        height: 12px;

        p {
            font-size: 10px;
            color: #fff;
            line-height: 1.4;
            width: 140px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow-wrap: break-word;
        }
    }
}

.preview-item-right-up {
    width: 45px;
    position: relative;
    margin: 85px 10px 0 0;
    float: right;
    .avatar {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #eee;
        position: absolute;
        top: -3px;
        left: -4px;
        border: 1px solid var(--primary-color);
    }
    .message-box {
        background-color: var(--primary-color);
        border-radius: 30px;
        height: 12px;

        p {
            font-size: 10px;
            color: #fff;
            line-height: 1.4;
            width: 140px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow-wrap: break-word;
        }
    }
}