.nav.flex-column {
    .nav-link {
        color: var(--primary-color);
        font-weight: 600;
        border-radius: 10px;
        margin-bottom: 10px;
        &.active {
            background: var(--primary-color);
            color: #fff !important;
        }
    }
}