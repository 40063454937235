label {
}

.form-control {
    position: relative;
    // min-width: 392px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    line-height: 1;
    // margin: 0 auto;

    &::placeholder {
        color: #ccc;
    }

    &:focus {
        border-color: var(--primary-color) !important;
    }

    &.form-control-xs {
        width: 100px !important;
    }
}

.form-horizontal {
    .form-group {
        display: flex;

        label {
            white-space: nowrap;
            width: 120px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 600;
            color: #6e6e6e;
            margin-top: 4px;
        }

        .form-control {
            max-width: 400px;
        }

        .quill {
            min-width: 400px;
            max-width: 400px;
            .ql-toolbar {
                border-color: #e2e2e2;
                border-radius: 10px 10px 0 0;
                background-color: #fafafa;
                padding: 4px 10px;
                button {
                    svg {
                        height: 16px;
                    }
                }
            }
            .ql-container {
                border-color: #e2e2e2;
                border-radius: 0 0 10px 10px;
                height: 64px;
                .ql-editor {
                    padding: 4px 10px;
                }
            }
        }
    }
}

// Checkbox
.custom-control-input {
    height: unset; // Fixed template bug
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    box-shadow: none;
}

// buttons
button {
    border-width: 0;
}
.btn {
    &:hover, &:active {
        box-shadow: none !important;
    }
    
    &.btn-icon {
        width: 50px;
    }

    &.btn-light {
        background-color: transparent;
        border-color: transparent;
        .icon {
            margin-right: 5px;
        }
        .label {
            font-weight: 600;
        }
        &:hover, &:active {
            .icon {
                color: #000;
            }
            .label {
                color: #000;
            }
            background-color: transparent !important;
            border-color: transparent !important;
        }
    }

    &.btn-primary {
        height: 38px;
        min-width: 100px;
        /* UI Properties */
        background: var(--primary-color);
        padding: 0 10px;
        border-radius: 10px;
        opacity: 1;
        font-size: var(--fontSize14);
        font-family: var(--primary-font);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-color: transparent;
        transition: all 0.15s linear;
        line-height: 1;

        .icon {
            margin-right: 5px;
        }
        .label {
            color: #fff;
        }
        &:hover, &:active {
            background-color: var(--primary-color-darker);
            border-color: transparent;
        }
    }

    &[disabled] {
        opacity: 0.5;
    }
}
