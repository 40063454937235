.container-tooltip{
    width: 474px;
    height: 273px;
    background: var(--background-secondary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 12px #BBBBBB29;
    opacity: 1;
    border-radius: 10px;
    .tooltip-icon-top{
        width: 38px;
        height: 28px;
        top: -18px;
        left: 3px;
        background-image: url('./../../../public/assets/icons/triangle-icon.png');
        position: absolute;
        background-repeat: no-repeat;
        
    }
    .tooltip-hearder{
        width: 474px;
        height: 96px;
        display: flex;
        justify-content: space-between;
        padding: 30px;

        .tooltip-icon{
            width: 38px;
            height: 38px;
            background-color: #efeefa;
            border: 1px solid #efeefa;
            border-radius: 10px;
            text-align: center;
        }
        .tooltip-title{
            width: calc(100% - 40px);
            height: 65px;
            font-size: var(--fontSize16);
            font-weight: var(--font-weight-semi-bold);
            color: var(--grey);
            padding: 0 0 0 10px;
            .tooltip-title-date{
                font-size: var(--fontSize16);
                font-weight: var(--font-weight-regular);
                color: var(--grey);
            }
            .tooltip-exit{
                font-size: var(--fontSize16);
                font-weight: var(--font-weight-semi-bold);
                position: absolute;
                top: 20px;
                right: 25px;
                cursor: pointer;

            }
        }
    }
    .tooltip-body-content{
        width: 466px;
        height: 142px;
        font-size: var(--fontSize12);
        font-weight: var(--font-weight-regular);
        word-wrap: normal;
        display:block;
        justify-content: space-between;
        word-wrap: break-word;
        line-height: 1.7;
        padding: 0 15px 0 30px;
        -webkit-line-clamp: 3;
    }
    .tooltip-action-event{
        width: 418px;
        text-align: right;
        position: relative;
        cursor: pointer;
        .button-icon{
            position: absolute;
            top: -7px;
            right: 105px;
        }
        .text-btn{
            font-size: var(--fontSize16);
            font-weight: var(--font-weight-regular);
            color: var(--danger-primary-color);
            position: absolute;
            top: -5px;
            right: 0;
        }
        
    }

}