$fontWeightDefault: 600;
@media screen and (max-width: 1000px) {
    #top-profile {
        margin: 0px 0px 0px 10px;
    }
    #top-settings {
        margin: 0px 0px 0px 10px;
    }
    #top-logout {
        margin: 0px 0px 0 10px;
    }
    .collapse:not(.show) {
        display: block !important;
        background: transparent;
    }
    .container-button-toggle {
        position: absolute;
        bottom: 26px;
        right: 425px;
    }
    .navbar-nav {
        position: relative;
        .nav-item {
            a {
                position: absolute;
                bottom: 0;
                right: 45px;
            }
            .dropdown-menu {
                position: absolute;
                top: 15px;
                right: 0;
                width: 100px;
                height: 100px;
                padding: 39px 0 0 70px;
                margin: -37px 0 0 56px;
                .active {
                    color: var(--primary-color) !important;
                }
            }
            .container-profile-topbar-nav {
                .full-name {
                    position: absolute;
                    bottom: 0;
                    right: 224px;
                }
                .role {
                    font-size: var(--fontSize12);
                    color: #787878;
                    font-weight: var(--font-weight-medium);
                    text-align: right;
                    position: absolute;
                    bottom: 6px;
                    right: 155px;
                    width: max-content;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #top-profile {
        margin: 0px 0px 0px 10px;
    }
    #top-settings {
        margin: 0px 0px 0px 10px;
    }
    #top-logout {
        margin: 0px 0px 0 10px;
    }
    .collapse:not(.show) {
        display: block !important;
        background: transparent;
    }
    .container-button-toggle {
        position: absolute;
        bottom: 26px;
        right: 360px;
    }
    .navbar-nav {
        position: relative;
        .nav-item {
            a {
                position: absolute;
                bottom: 0;
                right: 45px;
            }
            .dropdown-menu {
                position: absolute;
                top: 15px;
                right: 0;
                width: 100px;
                height: 100px;
                padding: 39px 0 0 0;
                margin: -37px 0 0 56px;
                .active {
                    color: var(--primary-color) !important;
                }
            }
            .container-profile-topbar-nav {
                .full-name {
                    position: absolute;
                    bottom: 0;
                    right: 224px;
                }
                .role {
                    font-size: var(--fontSize12);
                    color: #787878;
                    font-weight: var(--font-weight-medium);
                    text-align: right;
                    position: absolute;
                    bottom: 6px;
                    right: 155px;
                    width: max-content;
                }
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .collapse:not(.show) {
        display: block !important;
        background: transparent;
    }
    .container-button-toggle {
        position: absolute;
        bottom: 26px;
        right: 330px;
    }
    .search-toggle.dropdown-toggle {
        margin: 0 0 0 -65px;
    }
    .navbar-nav {
        position: relative;
        .nav-item {
            a {
                position: absolute;
                bottom: 0;
                left: 0px;
            }
            .dropdown-menu {
                position: absolute;
                top: 15px;
                right: 0;
                width: 100px;
                height: 100px;
                padding: 39px 0 0 0;
                margin: -37px 0 0 56px;
                .active {
                    color: var(--primary-color) !important;
                }
            }
            .container-profile-topbar-nav {
                .full-name {
                    position: absolute;
                    bottom: 0;
                    right: 214px;
                }
                .role {
                    font-size: var(--fontSize12);
                    color: #787878;
                    font-weight: var(--font-weight-medium);
                    text-align: right;
                    position: absolute;
                    bottom: 6px;
                    right: 155px;
                    width: max-content;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .collapse:not(.show) {
        display: block !important;
        background: transparent;
    }
    .container-button-toggle {
        position: absolute;
        bottom: 26px;
        right: 350px;
    }
    .search-toggle.dropdown-toggle {
        margin: 0 0 21px -65px;
    }
    .navbar-nav {
        position: relative;
        .nav-item {
            a {
                position: absolute;
                bottom: 0;
                left: 0px;
            }
            .dropdown-menu {
                position: absolute;
                top: 15px;
                right: 0;
                width: 100px;
                height: 100px;
                padding: 39px 0 0 0;
                margin: -37px 0 0 56px;
                .active {
                    color: var(--primary-color) !important;
                }
            }
            .container-profile-topbar-nav {
                .full-name {
                    position: absolute;
                    bottom: 20px;
                    right: 204px;
                }
                .role {
                    font-size: var(--fontSize12);
                    color: #787878;
                    font-weight: var(--font-weight-medium);
                    text-align: right;
                    position: absolute;
                    bottom: 25px;
                    right: 135px;
                    width: max-content;
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .collapse:not(.show) {
        display: block !important;
        background: transparent;
    }
    .container-button-toggle {
        position: absolute;
        bottom: 26px;
        right: 280px;
    }
    .search-toggle.dropdown-toggle {
        margin: 0 0 21px -35px;
    }
    .navbar-nav {
        position: relative;
        .nav-item {
            a {
                position: absolute;
                bottom: 0;
                left: 0px;
            }
            .dropdown-menu {
                position: absolute;
                top: 15px;
                left: -224px;
                width: 100px;
                height: 100px;
                padding: 39px 0 0 0;
                margin: -37px 0 0 56px;
                .active {
                    color: var(--primary-color) !important;
                }
                .iq-sub-dropdown {
                    width: 200px !important;
                    right: -30px !important;
                    top: 0;

                    &.show {
                        transform: translateY(35%) !important;
                    }
                    .card {
                        margin-bottom: 0;
                        height: 150px;
                        .card-body {
                            .iq-sub-card {
                                font-size: 14px;
                                font-weight: 600 !important;
                                color: #333;

                                display: flex;
                                align-items: center;

                                svg {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .container-profile-topbar-nav {
                .full-name {
                    position: absolute;
                    bottom: 33px;
                    right: 130px;
                    width: 125px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px !important;
                }
                .role {
                    font-size: var(--fontSize12);
                    color: #787878;
                    font-weight: var(--font-weight-medium);
                    text-align: right;
                    position: absolute;
                    bottom: 21px;
                    right: 202px;
                    width: max-content;
                    font-size: 11px !important;
                }
            }
        }
    }
}

.custom-switch-inner {
    height: 18px;
}
.navbar-nav {
    position: relative;
    .nav-item {
        a {
            line-height: 1;
        }
        .dropdown-menu {
            position: absolute;
            top: 15px;
            right: 0;
            .active {
                color: var(--primary-color) !important;
            }
        }
        .container-profile-topbar-nav {
            .full-name {
                font-weight: var(--font-weight-bold);
                color: var(--grey-dark);
                font-size: var(--fontSize16);
                margin-bottom: 5px;
            }
            .role {
                font-size: var(--fontSize12);
                color: #787878;
                font-weight: var(--font-weight-medium);
                text-align: right;
                margin-bottom: 0;
            }
        }
    }
}

.user-avatar {
    height: 45px;
    width: 45px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    .user-avatar-online {
        height: 15px;
        width: 15px;
        background-color: var(--success-primary-color);
        border-radius: 50%;
        display: inline-block;
        border: 2px solid var(--border-primary-color);
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .user-profile {
        height: 45px;
        width: 45px;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
}

.lable-switch-off-on {
    margin: -6px 0 0 53px;
    position: absolute;
}
.iq-top-navbar {
    position: relative !important;
}

.iq-sub-dropdown {
    width: 200px !important;
    right: -30px !important;
    top: 0;

    &.show {
        transform: translateY(35%) !important;
    }
    .card {
        margin-bottom: 0;
        .card-body {
            .iq-sub-card {
                font-size: 14px;
                font-weight: 600 !important;
                color: #333;

                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 999px) {
    #top-profile {
        margin: 0px 0px 30px 10px;
    }
    #top-settings {
        margin: 0px 0px 0px 10px;
    }
    #top-logout {
        margin: 0px 0px -10px 10px;
    }
}
