.current-visitor {
    margin-bottom: 15px;
    .view-all-container {
        display: flex;
        justify-content: center;
    
    }
}
.container-cards {
    div[class^="col-"] {
        // padding-left: 0;

        &:first-of-type {
            padding-left: 15px;
        }

        &:last-of-type {
            padding-right: 15px;
        }

        .card {
            margin-bottom: 15px;
        }
    }
}

@media (min-width: 1024px){
    .col-lg-3-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 1440px){
    .col-lg-6-5 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}


